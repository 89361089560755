import { Button, Container, Header, SpaceBetween, Spinner, Table } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { useDashboardContext } from 'providers/DashboardProvider';
import { API_URL_PATH_DM_CLAIMED } from 'constants/urls';
import { AggregateItem, AggregatedAlertCode, LocationNode, StateAlertCode } from 'types/custom';
import { deviceManagerAPI } from 'api';
import './index.css';
import { EmptyStateContent } from 'components/table-content';
import AlertStatusBadge from 'components/alert-status/AlertStatusBadge';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import { toTitleCase } from 'utils';
import { usePageLayoutContext } from 'components/common/layout';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';

const AggregateData = () => {
    const { selectedNode, selectedPath } = useDashboardContext();
    const [items, setItems] = useState<Partial<Record<AggregatedAlertCode, AggregateItem[]>>>({});
    const [tableItems, setTableItems] = useState<AggregateItem[]>([]);
    const [clickedBox, setClickedBox] = useState(AggregatedAlertCode.N);

    const { setNotification } = usePageLayoutContext();

    const {
        refetch: getAggregateData,
        data: resp,
        isLoading: dataLoading,
        status: dataStatus,
        isFetching: dataFetching,
    } = useFetchWithReactQuery<AggregateItem[]>({
        axiosInstance: deviceManagerAPI,
        url: `${API_URL_PATH_DM_CLAIMED}/aggregated?${selectedPath}`,
        key: `aggregated-${selectedPath}`,
    });

    const handleClick = (status: AggregatedAlertCode) => {
        setTableItems(items?.[status] || []);
        setClickedBox(AggregatedAlertCode[status]);
    };

    // Polyfill and replacement for Object.groupBy
    const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);

    useEffect(() => {
        if (dataFetching) return;
        if (dataStatus === 'success') {
            const aggregatedItems = groupBy(resp, (item: AggregateItem) => item.alertCode);
            setItems(aggregatedItems);
            setTableItems(aggregatedItems?.[clickedBox] || []);
        } else if (dataStatus === 'error') {
            setNotification([
                {
                    type: 'error',
                    content: 'Error fetching aggregated data',
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setNotification([]),
                    id: 'fetch-aggregated-data-error',
                },
            ]);
        }
    }, [dataStatus, dataFetching]);

    const groups: [AggregatedAlertCode, string][] = [
        [AggregatedAlertCode.LL, 'red'],
        [AggregatedAlertCode.L, 'yellow'],
        [AggregatedAlertCode.N, 'green'],
        [AggregatedAlertCode.U, 'green'],
        [AggregatedAlertCode.H, 'yellow'],
        [AggregatedAlertCode.HH, 'red'],
    ]

    return (<SpaceBetween size='m'>
        <Container header={
            <Header variant='h2' actions={<Button iconName='refresh' loading={dataFetching} onClick={() => getAggregateData()} />}>
                {toTitleCase((selectedNode as LocationNode)?.title || 'Unknown')}: {selectedNode?.name}
            </Header>
        }>
            {dataLoading ? (
                <Spinner size='big' />
            ) : (
                <SpaceBetween direction='horizontal' size='xs'>
                    {groups.map((group) => (<div
                        className={`aggregate-box ${group[1]} ${clickedBox === group[0] ? 'active' : ''}`}
                        key={group[0]}
                        onClick={() => handleClick(group[0])}
                    >
                        <div className='count'>
                            {items[group[0]]?.length || 0}
                        </div>
                        <div style={{backgroundColor: 'lightgrey'}}>
                            <AlertStatusBadge code={group[0] as unknown as StateAlertCode} noColor={true}/>
                        </div>
                    </div>))}
                </SpaceBetween>
            )}
        </Container>
        <Container fitHeight={true}>
            <Table
                columnDefinitions={[
                    {
                        id: 'friendlyName',
                        header: 'Friendly Name',
                        cell: (item: AggregateItem) => item.friendlyName,
                        sortingField: 'name',
                    },
                    {
                        id: 'measurement',
                        header: 'Measurement',
                        cell: (item: AggregateItem) => snakeCaseToNormalCase(item.measurement),
                        sortingField: 'measurement',
                    },
                    {
                        id: 'alertCode',
                        header: 'Alert Code',
                        cell: (item: AggregateItem) => (<AlertStatusBadge code={item.alertCode as unknown as StateAlertCode}/>),
                        sortingField: 'alertCode',
                    },
                    {
                        id: 'value',
                        header: 'Value',
                        cell: (item: AggregateItem) => item.value,
                        sortingField: 'value',
                    },
                ]}
                items={tableItems}
                loadingText='Loading resources'
                stripedRows
                sortingDisabled
                variant='embedded'
                empty={<EmptyStateContent name='alerts' />}
                header={<Header>Alerts</Header>}
            />
        </Container>
    </SpaceBetween>);
};

export default AggregateData;
