import {
    Grid,
    ColumnLayout,
    Container,
    Header,
    Button,
} from '@cloudscape-design/components';
import PlansList from './PlansList';
import PlansView from './PlansView';
import { useIncidentManagerContext } from 'pages/incident-manager/IncidentManagerPage';

const EscalationPlans = () => {
    const { plansRefetch, plansFetching } = useIncidentManagerContext(); 
    return (
        <Grid
            gridDefinition={[
                { colspan: { m: 4, s: 3, xs: 12, xxs: 12 } },
                { colspan: { m: 8, s: 9, xs: 12, xxs: 12 } },
            ]}
        >
            <ColumnLayout variant='text-grid'>
                <Container
                    header={<Header variant='h3' actions={
                        <Button iconName='refresh' onClick={plansRefetch} loading={plansFetching}/>
                    }>Escalation Plans</Header>}
                    fitHeight={true}
                >
                    <PlansList />
                </Container>
            </ColumnLayout>
            <ColumnLayout variant='text-grid'>
                <Container
                    // header={<Header variant='h3'>Update a plan</Header>}
                    fitHeight={true}
                >
                    <PlansView />
                </Container>
            </ColumnLayout>
        </Grid>
    );
};

export default EscalationPlans;
