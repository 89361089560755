import { Tabs } from '@cloudscape-design/components';
import { useIncidentManagerContext } from 'pages/incident-manager/IncidentManagerPage';
import Contacts from '../Contacts';
import EscalationPlans from '../EscalationPlans';
import NotificationGroups from '../NotificationGroups';

const IncidentManagerMainTabs = () => {
    const { activeMainTabId, setActiveMainTabId } =
        useIncidentManagerContext();

    return (
        <Tabs
            tabs={[
                {
                    label: 'Contacts',
                    id: 'contacts',
                    content: <Contacts />,
                },
                {
                    label: 'Notification Groups',
                    id: 'notification-groups',
                    content: <NotificationGroups />,
                },
                {
                    label: 'Escalation Plans',
                    id: 'escalation-plans',
                    content: <EscalationPlans />,
                },
            ]}
            activeTabId={activeMainTabId}
            onChange={({ detail }) => setActiveMainTabId(detail.activeTabId)}
        />
    );
};

export default IncidentManagerMainTabs;
