import {
    Box,
    Button,
    Modal,
    SpaceBetween,
} from '@cloudscape-design/components';

type DisableModalProps = {
    visible: boolean;
    onDiscard: () => void;
    onDisable: () => void;
    itemName: any[] | undefined;
    moduleName: string;
}

const DisableModal = ({
    visible,
    onDiscard,
    onDisable,
    itemName,
    moduleName,
}: DisableModalProps) => {
    const getNames = (itemName: any[] | undefined) => {
        const names = itemName!?.map((item) => {
            return ' ' + item['name'];
        });

        return names.toString();
    };

    return (
        <>
            <Modal
                onDismiss={onDiscard}
                visible={visible}
                closeAriaLabel='Close modal'
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={onDiscard}>
                                Cancel
                            </Button>
                            <Button variant='primary' onClick={onDisable}>
                                Disable
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={`Disable ${moduleName}`}
            >
                Are you sure you want to disable the following{' '}
                {moduleName.toLowerCase()}s?{' '}
                <b>
                    <ul>
                        {itemName!?.length > 1 ? (
                            getNames(itemName)
                        ) : (
                            <li>
                                {itemName![0]?.name ||
                                    itemName![0]?.friendlyName}
                            </li>
                        )}
                    </ul>
                </b>
            </Modal>
        </>
    );
};

export default DisableModal;
