export const COLUMN_DEFINITIONS = [
    {
        id: 'groupId',
        header: 'Name',
        cell: (item: any) => item.groupId,
        sortingField: 'groupId',
    },
    {
        id: 'friendlyName',
        header: 'Friendly Name',
        cell: (item: any) => item.friendlyName,
        sortingField: 'friendlyName',
    },
    {
        id: 'description',
        header: 'Description',
        cell: (item: any) => item.description,
        sortingField: 'description',
    },
];
