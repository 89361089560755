import { Button, SegmentedControl, SpaceBetween } from '@cloudscape-design/components';

import { useDashboardContext } from 'providers/DashboardProvider';
import HierarchyTree from './hierarchy/HierarchyTree';
import LocationTree from './location/LocationTree';

const LocationHierarchy = () => {
    const { isUsingLocationsHierarchy, setIsUsingLocationsHierarchy, expandAll, setExpandAll } = useDashboardContext();

    return (
        <SpaceBetween direction='vertical' size='s'>
            <SpaceBetween size='xxs' direction='horizontal'>
                <SegmentedControl
                    selectedId={isUsingLocationsHierarchy ? "locations" : "tags"}
                    onChange={({detail}) =>
                        setIsUsingLocationsHierarchy(detail.selectedId === "locations")
                    }
                    label=""
                    options={[
                        { text: "Tags", id: "tags" },
                        { text: "Locations", id: "locations" },
                    ]}
                />
                {!isUsingLocationsHierarchy && <Button variant="normal" loading={expandAll} onClick={() => setExpandAll(!expandAll)}>Expand all</Button>}
                </SpaceBetween>
            {isUsingLocationsHierarchy ? <LocationTree /> : <HierarchyTree />}
        </SpaceBetween>
    );
};

export default LocationHierarchy;
