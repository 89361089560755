export default {
  accentGreen: '#00E47C',
  accentGreen2: '#00B663',
  darkGreen: '#08312A',
  darkGreen2: '#051D19',
  darkGreen3: '#6B8375',
  darkGreen4: '#B6CDBF',
  success: '#1EAD1B',
  success2: '#0C490C',
  error: '#F62B2B',
  error2: '#6D1313',
  warning2: '#893406',
  information: '#0075FF',
  information2: '#003471'
} as const
