import {
    Box,
    Header,
    SpaceBetween,
    Table,
    Button,
    Alert,
    Spinner,
    Link,
    SelectProps,
    Select,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';

import {
    API_URL_PATH_IM_INCIDENTS,
    API_URL_PATH_DM_DEVICE,
    API_URL_PATH_IM_PLANS,
} from 'constants/urls';
import { deviceManagerAPI, incidentManagerAPI } from 'api';
import { PaginationContent } from 'components/table-content';
import { EmptyState } from 'components/empty-state/EmptyState';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { AlertRule, Asset, EscalationPlan, Incident, IncidentActivity, IncidentStatus } from 'types/custom';
import IncidentStatusBadge from 'components/incident-status/IncidentStatusBadge';
import { getMatchesCountText, thresholdCodeToName } from 'utils';
import IncidentDetailsModal from '../IncidentDetailsModal';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import IncidentAddActivity from '../IncidentAddActivity';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

const IncidentsTable = ({ selectedDevices }:{ selectedDevices: Asset }) => {
    const [allIncidents, setAllIncidents] = useState<Incident[]>([]);
    const [selectedIncident, setSelectedIncident] = useState<Incident[]>([]);
    const [showIncidentDetailsModal, setShowIncidentDetailsModal] = useState(false);

    const [allMeasures, setAllMeasures] = useState<SelectProps.Options>([]);
    const [selectedMeasure, setSelectedMeasure] = useState<OptionDefinition>({ value: '', label: 'Any Measure' });
    const [selectedStatus, setSelectedStatus] = useState<OptionDefinition>({ value: '', label: 'Any Status' });

    const { data: rulesResponse, error: rulesError, status: rulesStatus } = useFetchWithReactQuery<AlertRule[]>({
		axiosInstance: deviceManagerAPI,
		url: `${API_URL_PATH_DM_DEVICE}/${selectedDevices.name}/rules`,
		key: `rules-${selectedDevices.name}`,
	});

    const getRule = (item: Incident) => rulesStatus === 'success' ?
    rulesResponse?.find((rule) => rule.id === item.alertRule)
    : undefined;

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        paginationProps,
    } = useCollection(allIncidents, {
        filtering: {
            empty: (
                <EmptyState
                    title="No incidents"
                    subtitle="No incidents to display."
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={
                        <Button onClick={() => actions.setFiltering('')}>
                            Clear filter
                        </Button>
                    }
                />
            ),
            filteringFunction: (item, _filter) => {
                if (selectedMeasure.value !== '' && getRule(item)?.measure !== selectedMeasure.value) return false;
                if (selectedStatus.value !== '' && item.status !== selectedStatus.value) return false;
                return true;
            },
        },
        pagination: { pageSize: 20 },
        sorting: {},
    });

    const {
        data: incidentsResponse,
        error: incidentsError,
        isLoading: incidentsLoading, 
        isFetching: incidentsFetching, 
        status: incidentsStatus,
        refetch: refetchIncidents
    } = useFetchWithReactQuery<{ items: Incident[] }>({
		axiosInstance: incidentManagerAPI,
		url: `${API_URL_PATH_IM_INCIDENTS}?resourceId=${selectedDevices.name}`,
		key: `incidents-${selectedDevices.name}`,
	});

    const { data: plansList } = useFetchWithReactQuery<{ items: EscalationPlan[] }>({
        axiosInstance: incidentManagerAPI,
        url: API_URL_PATH_IM_PLANS,
        key: 'escalation-plans',
    });

    useEffect(() => {
        if (incidentsStatus === 'success') {
            setAllIncidents(incidentsResponse?.items);
            if (!selectedIncident.length) setSelectedIncident(
                incidentsResponse?.items?.slice(0,1) || []
            );
            setAllMeasures([
                { value: '', label: 'Any Measure' },
                ...Object.keys(selectedDevices.state || {}).map((measure) => ({ label: snakeCaseToNormalCase(measure), value: measure }))
            ]);
        }
    }, [incidentsStatus, incidentsResponse]);

    const getLastEvent = (item: Incident) => {
        const event = (item.activities || []).map((activity) => [IncidentActivity.COMMENT, IncidentActivity.ACKNOWLEDGE, IncidentActivity.RESOLUTION].includes(activity.type)).lastIndexOf(true);
        return event !== -1 ? `${item.activities[event].value} at ${new Date(item.activities[event].time || NaN).toLocaleString(undefined, {timeZoneName: "shortOffset"})}` : undefined;
    }

    if (incidentsError) return <Alert type='error' header='Error loading alert rules'>{incidentsError.toString()}</Alert>;
    if (rulesError) return <Alert type='error' header='Error loading alert rules'>{rulesError.toString()}</Alert>;

    const failoverColumn = (item: Incident) => rulesStatus === 'success' && !getRule(item) ? '-' : <Spinner/>;

    return (
        <>
            <Table
                {...collectionProps}
                onSelectionChange={({ detail }) =>
                    setSelectedIncident(detail.selectedItems)
                }
                variant='embedded'
                selectedItems={selectedIncident}
                columnDefinitions={[
                    {
                        id: 'createdAt',
                        header: 'Started',
                        cell: (item: Incident) => (<Link onFollow={() => {
                            setSelectedIncident([item])
                            setShowIncidentDetailsModal(true)
                        }}>
                            {item.createdAt ? new Date(item.createdAt * 1000).toLocaleString() : '-'}
                        </Link>),
                        sortingField: 'createdAt',
                    },
                    {
                        id: 'status',
                        header: 'Status',
                        cell: (item: Incident) => (<IncidentStatusBadge status={item.status}/>),
                        sortingField: 'status',
                    },
                    {
                        id: 'measure',
                        header: 'Measure',
                        cell: (item: Incident) => snakeCaseToNormalCase(getRule(item)?.measure || '') ?? failoverColumn(item),
                        sortingField: 'measure',
                    },
                    {
                        id: 'type',
                        header: 'Type',
                        cell: (item: Incident) => thresholdCodeToName(getRule(item)?.threshold_code) ?? failoverColumn(item),
                        sortingField: 'threshold_code',
                    },
                    {
                        id: 'threshold',
                        header: 'Threshold',
                        cell: (item: Incident) => getRule(item)?.threshold_value ?? failoverColumn(item),
                        sortingField: 'threshold_value',
                    },
                    {
                        id: 'lastEvent',
                        header: 'Last Event',
                        cell: (item: Incident) => getLastEvent(item)
                    },
                ]}
                items={items}
                loadingText='Loading resources'
                loading={incidentsLoading}
                selectionType='multi'
                stripedRows
                resizableColumns
                empty={
                    <Box textAlign='center' color='inherit'>
                        <Box
                            padding={{ bottom: 's' }}
                            variant='p'
                            color='inherit'
                        >
                            No incidents to display.
                        </Box>
                    </Box>
                }
                pagination={
                    <PaginationContent paginationProps={paginationProps} />
                }
                filter={
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Select
                        inlineLabelText="Filter status"
                        selectedOption={selectedStatus}
                        disabled={incidentsLoading}
                        onChange={({ detail }) => {
                            setSelectedStatus(detail.selectedOption);
                        }}
                        loadingText='Loading'
                        options={[
                            { value: '', label: 'Any Status' },
                            { value: IncidentStatus.OPEN, label: 'Open _' },
                            { value: IncidentStatus.ACKNOWLEDGED, label: 'Acknowledged' },
                            { value: IncidentStatus.CLEARED, label: 'Cleared' },
                            { value: IncidentStatus.RESOLVED, label: 'Resolved' },
                            { value: IncidentStatus.FORWARDED, label: 'Forwarded' },
                        ]}
                        />
                        <Select
                        inlineLabelText="Filter measure"
                        selectedOption={selectedMeasure}
                        disabled={incidentsLoading}
                        onChange={({ detail }) => {
                            setSelectedMeasure(detail.selectedOption);
                        }}
                        loadingText='Loading'
                        options={allMeasures}
                        empty='No options'
                        />
                    </SpaceBetween>
                }
                header={
                    <Header
                        counter={`${selectedIncident?.length}/${allIncidents.length}${(filteredItemsCount ? ', ' + getMatchesCountText(filteredItemsCount) : '')}`}
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button iconName='refresh' loading={incidentsFetching} onClick={() => refetchIncidents()}/>
                                <IncidentAddActivity
                                incidents={selectedIncident}
                                refetchIncidents={refetchIncidents}
                                loading={incidentsFetching}/>
                                <Button onClick={() => setShowIncidentDetailsModal(true)} disabled={selectedIncident.length !== 1}>Details</Button>
                            </SpaceBetween>
                        }
                    >
                        Incidents
                    </Header>
                }
            />
            {showIncidentDetailsModal && (
                <IncidentDetailsModal
                    visible={showIncidentDetailsModal}
                    setVisible={setShowIncidentDetailsModal}
                    incident={selectedIncident[0]}
                    alertRule={getRule(selectedIncident[0])!}
                    escalationStages={plansList?.items.find((plan) => plan.name === getRule(selectedIncident[0])?.escalation_plan)?.stages}
                    refetchIncidents={refetchIncidents}
                    loading={incidentsFetching}
                />
            )}
        </>
    );
};

export default IncidentsTable;
