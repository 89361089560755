import { useEffect, useState } from 'react';

import { ContentLayout, FormField, Header, Select } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { DeviceManagerContext } from 'pages/device-manager/DeviceListPage';
import DeviceTable from 'components/device-manager/DeviceTable';
import { ThresholdPicker } from 'components/device-manager/DeviceAlertMgr/CreateAlertModal/ThresholdPicker';
import { deviceManagerAPI, userManagerAPI } from 'api';
import { toTitleCase } from 'utils';
import { Asset, DeviceGroupProps } from 'types/custom';
import colors from '../../theme/colors';
import { getDevices } from './utils'
import { API_URL_PATH_DM_GROUP, API_URL_PATH_GROUP_LIST } from 'constants/urls';
import AlertsTable from 'components/device-manager/DeviceAlertMgr/AlertsTable';

const AlertRulesManager = () => {
  const [selectedDevices, setSelectedDevices] = useState<Asset[]>([]);
  const [devicesInGroup, setDevicesInGroup] = useState<Asset[]>([]);
  const [deviceGroupsOptions, setDeviceGroupsOptions] = useState<OptionDefinition[]>([]);
  const [selectedDeviceGroupOption, setSelectedDeviceGroupOption] = useState<OptionDefinition>({ label: 'All', value: '' });
  const [deviceGroups, setDeviceGroups] = useState<DeviceGroupProps[]>([]);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(false);
  const [measurement, setMeasurement] = useState<OptionDefinition | null>(null);
  const [selectedThreshold, setSelectedThreshold] = useState<string>('');

  const { data: deviceGroupRules } = 
    useFetchWithReactQuery({
      key: `device-group-${selectedDeviceGroupOption.value}`,
      axiosInstance: deviceManagerAPI,
      url: `${API_URL_PATH_DM_GROUP}/${selectedDeviceGroupOption.value}/rules`,
      enabled: selectedDeviceGroupOption.value !== ''
    });

  const { data: allDeviceGroups } =
    useFetchWithReactQuery({
      axiosInstance: userManagerAPI,
      key: 'device-groups',
      url: API_URL_PATH_GROUP_LIST,
    });

  useEffect(() => {
    if (allDeviceGroups) {
      setDeviceGroups(allDeviceGroups.items);
      const formattedList = allDeviceGroups.items.map(
        (el: DeviceGroupProps) => {
          return { label: el.groupId, value: el.groupId };
        }
      );
      setDeviceGroupsOptions(formattedList);
      setSelectedDeviceGroupOption(formattedList[0]);
    }
  }, [allDeviceGroups]);

  const selectedGroup = deviceGroups.find(x => x.groupId === selectedDeviceGroupOption?.value);
  const deviceIds = selectedGroup?.assets?.map(x => x.assetId) ?? [];
  const loadDevices = async () => {
    if (deviceIds.length === 0) return;
    const devices = await getDevices(deviceIds);
    setDevicesInGroup(devices);
  }

  useEffect(() => {
    setDevicesLoading(true);
    setSelectedDevices([]);
    loadDevices().finally(() => {
      setDevicesLoading(false);
    });
  }, [selectedDeviceGroupOption.value]);

  const allMeasurements = devicesInGroup.reduce(
    (prev, current) => [...prev, ...Object.keys(current.state)],
    [] as string[]
  );
  const measurementOptions = allMeasurements.map((x: string) => ({
    label: toTitleCase(x.replace(/_/gm, ' ')),
    value: x,
  }));

  return (
    <ContentLayout
      defaultPadding
      headerBackgroundStyle={colors.accentGreen2}
      header={<Header variant="h1">Alert Rules Manager</Header>}
    >
      <div style={{ maxWidth: '230px' }}>
        <Select
          inlineLabelText="Device Group"
          options={deviceGroupsOptions}
          selectedOption={selectedDeviceGroupOption}
          onChange={(event) =>
            setSelectedDeviceGroupOption(event.detail.selectedOption)
          }
        />
      </div>

      <DeviceManagerContext.Provider
        value={{
          deviceLoading: devicesLoading,
          allDevices: devicesInGroup,
          getAllDevices: loadDevices,
          setSelectedDevices,
          selectedDevices,
          deviceError: '',
          activeTabId: '',
          setActiveTabId: () => {},
          getDeviceDetails: () => {},
          refetchDeviceDetails: () => {},
          deviceResponse: {},
          deviceStatus: 0,
        }}
      >
        <DeviceTable />
      </DeviceManagerContext.Provider>

      <div style={{ maxWidth: '230px' }}>
        <Select
          inlineLabelText="Measurements"
          options={measurementOptions}
          selectedOption={measurement}
          onChange={(event) => setMeasurement(event.detail.selectedOption)}
        />
      </div>

      <FormField label="Threshold">
        <ThresholdPicker onChange={setSelectedThreshold} />
      </FormField>

      {devicesInGroup?.[0] && (
        <AlertsTable 
          selectedDevices={devicesInGroup?.[0]}
        />
      )}

    </ContentLayout>
  );
};

export default AlertRulesManager;
