import { StatusIndicator } from "@cloudscape-design/components";
import { AssetAlertRuleStatus } from "types/custom";

function AlertStatusIndicator({ status } : {status: AssetAlertRuleStatus | undefined}) {
	switch (status) {
		case AssetAlertRuleStatus.DISABLED:
			return <StatusIndicator type='warning'>Disabled</StatusIndicator>
		case AssetAlertRuleStatus.DELETED:
			return <StatusIndicator type='stopped'>Deleted</StatusIndicator>
		case AssetAlertRuleStatus.ENABLED:
			return <StatusIndicator type='info'>Enabled</StatusIndicator>;
		default:
			return <StatusIndicator type='error'>Unknown</StatusIndicator>;
	}
}

export default AlertStatusIndicator;