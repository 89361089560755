import { TreeItem } from "@mui/x-tree-view";
import useFetch from "hooks/useFetch";
import { useDrop, useDrag } from 'react-dnd';
import { MouseEvent, useEffect, useRef, useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { FormField, Multiselect, Modal, Button } from "@cloudscape-design/components";

import { deviceManagerAPI } from "api";
import { HierarchyNode } from "types/custom";
import { API_URL_PATH_TAG } from "constants/urls";
import { usePageLayoutContext } from "components/common/layout";
import { makeNodeFromSensor } from "./utils";
import { useDashboardContext } from "providers/DashboardProvider";
import { colors } from "pages/dashboard/DashboardPage/utils";
import AodIcon from '@mui/icons-material/Aod';
import FolderIcon from '@mui/icons-material/Folder';

export type HierarchyTreeItemProps = {
  node: HierarchyNode;
  isRootTag: boolean;
  canHaveChildren: boolean;
  enabledDeviceOptions: any[];
  hierarchyId: string;
  devicesPresentInTree: string[];

  setDevicesPresentInTree: (devices: string[]) => void;
  onNodeDelete: (node: HierarchyNode) => void;
  onDeviceDetach: (node: HierarchyNode) => void;
  onItemClick: (node: HierarchyNode) => void;
  onNodeAdded: (parent: HierarchyNode, child: HierarchyNode) => void;
  onItemDropped: (source: HierarchyNode, destination: HierarchyNode) => void;
  setDeviceDetailsModal: (deviceName: string | null) => void;
};

const DRAGGABLE_TYPE = 'HIERARCHY_TREE_ITEM';

const HierarchyItem = ({
  node,
  isRootTag,
  hierarchyId,
  canHaveChildren,
  enabledDeviceOptions,
  devicesPresentInTree,
  setDevicesPresentInTree,
  onItemClick,
  onItemDropped,
  onNodeDelete,
  onDeviceDetach,
  onNodeAdded,
  setDeviceDetailsModal,
}: HierarchyTreeItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [nodeName, setNodeName] = useState(node.name);
  const [isAddDeviceModalVisible, setIsAddDeviceModalVisible] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<[]>([]);
  const [isAddingTag, setAddingTag] = useState(false);

  const { setNotification } = usePageLayoutContext();
  const { setChartDevices, chartDevices, selectedMeasurements, setSelectedMeasurements } = useDashboardContext();

  const ref = useRef(null);
  const moreIconRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const key = `${node.id}-${node.name}=${node.children?.length}`;

  const {
    fetchData: addDevicesToTag,
    error: addDevicesToTagError,
  } = useFetch(
    {
      axiosInstance: deviceManagerAPI,
      method: 'PATCH',
    },
    { manual: true }
  );

  const { fetchData: createTag } = useFetch({
    axiosInstance: deviceManagerAPI,
    method: 'POST',
    url: API_URL_PATH_TAG,
    data: {
      name: 'New Tag',
      hierarchyId,
      assetList: []
    }
  }, { manual: true });

  const { fetchData: renameTag } = useFetch({
    axiosInstance: deviceManagerAPI,
    method: 'PATCH',
    url: `${API_URL_PATH_TAG}/${node.id}`,
    data: {
      name: node.name,
    }
  }, { manual: true });

  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_TYPE,
      drop(_item: any, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        onItemDropped(_item, node);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [node],
  );

  const [, drag] = useDrag(() => ({
    type: DRAGGABLE_TYPE,
    item: node,
  }), [node]);

  drag(drop(ref));

  const handleOptionsMenu = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsOpen(!isOpen);
  };

  const handleNodeClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, node: HierarchyNode) => {
    e.stopPropagation();
    e.preventDefault();

    if (node.isDevice) {
      setSelectedMeasurements(new Map());
      const availableColors = colors.filter(color => !chartDevices.some(device => device.color === color));
      setChartDevices([{ ...node, color: availableColors[0] }]);
    }

    onItemClick(node);
  };

  const handleOnChangeNodeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setNodeName(e.target.value);
    node.name = e.target.value;
  };

  const handleRenameNode = () => {
    setIsRenaming(true);
    setIsOpen(false);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && isRenaming) {
      setIsRenaming(false);
      await saveNodeName();
    }
  };

  const handleAddNode = async () => {
    setAddingTag(true);

    const tagResponse = await createTag();

    if (!tagResponse) {
      setNotification([
        {
          type: 'error',
          content: 'Failed to create tag',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'create-tag-error',
        },
      ]);
      return;
    }

    setAddingTag(false);

    const newNode = {
      title: tagResponse.data.name,
      tagId: tagResponse.data.id,
      id: tagResponse.data.id,
      name: tagResponse.data.name,
      assetList: [],
      isNew: true,
      children: [],
      hierarchyId: tagResponse.data.hierarchyId,
      data: {},
    };

    if (node.children) {
      node.children.push(newNode);
    }

    onNodeAdded(node, newNode);
    setIsOpen(false);
  };

  const saveNodeName = async () => {
    setIsRenaming(false);
    setIsOpen(false);

    if (!node.isDevice) {
      await renameTag();
    };
  };

  const onAddDevicesToTag = async () => {
    if (selectedDevices.length === 0) {
      setNotification([
        {
          type: 'error',
          content: 'Please select at least one device to add to the tag',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'devices-not-selected-error',
        },
      ]);
      return;
    }

    const previousAssetList = node.assetList || [];

    const response = await addDevicesToTag(`${API_URL_PATH_TAG}/${node.id}`, {
      assetList: [...previousAssetList, ...selectedDevices.map((device: any) => device.value)].filter((v, i, a) => a.indexOf(v) === i),
      runDuplicateAssetValidation: false,
    });

    if (response && response.status === 200) {
      setNotification([
        {
          type: 'success',
          content: 'Devices added successfully',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'add-devices-to-tag-success',
        },
      ]);

      const deviceNodes = selectedDevices.map(makeNodeFromSensor);
      node.children.push(...deviceNodes);
      onNodeAdded(node, deviceNodes[0]);

      const selectedDeviceIds = selectedDevices.map((device: any) => device.value);
      setDevicesPresentInTree([...devicesPresentInTree, ...selectedDeviceIds]);
    } else {
      setNotification([
        {
          type: 'error',
          content: 'Failed to add devices to the tag.' + addDevicesToTagError,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'add-devices-to-tag-error',
        },
      ]);
    }

    setSelectedDevices([]);
    setIsAddDeviceModalVisible(false);
  };

  const handleSelectDevice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const availableColors = colors.filter(color => !chartDevices.some(device => device.color === color));
    if (availableColors.length === 0) {
      setNotification([
        {
          type: 'error',
          content: 'You can only select up to 10 devices',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'device-selection-limit-error',
        },
      ]);
      return;
    }

    if (e.target.checked && !chartDevices.some(device => device.name === node.name)) {
      setChartDevices([...chartDevices, { ...node, color: availableColors[0] }]);
    } else {
      setChartDevices(chartDevices.filter((device) => device.name !== node.name));
      const newSelectedMeasurements = new Map(selectedMeasurements);
      newSelectedMeasurements.forEach((value, key) => {
        newSelectedMeasurements.set(key, value.filter((device) => device !== node.name));
      });

      setSelectedMeasurements(newSelectedMeasurements);
    }
  };

  const onMultiSelectChange = ({ detail }: any) => {
    setSelectedDevices(detail.selectedOptions);
  };

  useEffect(() => {
    if (isRenaming && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isRenaming, node.children, node.name, nodeName]);

  useEffect(() => {
    if (node.isNew) {
      setIsRenaming(true);

      node.isNew = false;

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [node]);

  return (
    <div key={key}>
      <Modal
        onDismiss={() => {
          setIsAddDeviceModalVisible(false);
          setSelectedDevices([]);
        }}
        visible={isAddDeviceModalVisible}
        header="Add Device"
      >
        <FormField
          label={
            <span>
              Claimed Devices
            </span>
          }
        >
          <Multiselect
            selectedOptions={selectedDevices}
            onChange={onMultiSelectChange}
            filteringType='auto'
            deselectAriaLabel={(e) => `Remove ${e.label}`}
            options={enabledDeviceOptions}
            placeholder='Choose devices to add'
            selectedAriaLabel='Selected'
            loadingText='Loading devices'
            empty='No options'
          />
        </FormField>

        <div style={{ marginTop: '20px' }}>
          <Button variant="primary" onClick={onAddDevicesToTag}>Save</Button>
        </div>
      </Modal>

      <Menu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={moreIconRef.current}>
        {!node.isDevice &&
          <div>
            <MenuItem onClick={handleAddNode}>Add Tag</MenuItem>
            <MenuItem onClick={() => {
              setIsAddDeviceModalVisible(true);
              setIsOpen(false);
            }}>
              Add Device
            </MenuItem>
            <MenuItem onClick={handleRenameNode}>Rename</MenuItem>
            {!isRootTag &&
              <MenuItem onClick={() => {
                onNodeDelete(node);
                setIsOpen(false);
              }}>Delete</MenuItem>
            }
          </div>
        }
        {node.isDevice &&
          <div>
            <MenuItem onClick={() => {
              onDeviceDetach(node);
              setIsOpen(false);
            }}>
              Detach Device
            </MenuItem>
            <MenuItem onClick={() => {
              setDeviceDetailsModal(node?.name || null);
              setIsOpen(false);
            }}>
              Device Details
            </MenuItem>
          </div>
        }
      </Menu>
      {isAddingTag && <CircularProgress size={25} style={{ color: 'black' }} />}
      <TreeItem
        ref={ref}
        key={key}
        itemId={node.id}
        disabled={!node.name}
        slots={{ icon: node.isDevice ? AodIcon : FolderIcon }}
        sx={{ "& > div": { padding: "2px" } }}
        onFocusCapture={(e: any) => e.stopPropagation()}
        label={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {isRenaming ? <input
              ref={inputRef}
              defaultValue={node.name}
              onKeyDown={handleKeyDown}
              onBlur={saveNodeName}
              onChange={handleOnChangeNodeName} /> :
              <span style={{ display: "flex" }}>
                {node.isDevice &&
                  <input
                    onClick={e => e.stopPropagation()}
                    checked={chartDevices.some(device => device.name === node.name)}
                    onChange={handleSelectDevice}
                    type="checkbox" />}
                <span style={{ marginLeft: "5px" }}>{node.friendlyName || node.name || <i>Loading...</i>}</span>
              </span>}
            <MoreHorizIcon ref={moreIconRef} color="disabled" onClick={(e: any) => handleOptionsMenu(e)} />
          </div>
        }
        onClick={(e: any) => handleNodeClick(e, node)}
        ContentProps={{
          style: {
            transition: '0.3s all',
            backgroundColor: `${isOverCurrent ? 'lightgrey' : ''}`,
            paddingBottom: isOverCurrent ? '50px' : '0',
          }
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map((childNode, childNodeIndex) =>
            <HierarchyItem
              key={childNodeIndex}
              node={childNode}
              isRootTag={false}
              hierarchyId={hierarchyId}
              enabledDeviceOptions={enabledDeviceOptions}
              canHaveChildren={canHaveChildren && !node.assetList?.length}
              onItemDropped={onItemDropped}
              onNodeDelete={onNodeDelete}
              onDeviceDetach={onDeviceDetach}
              onNodeAdded={onNodeAdded}
              setDevicesPresentInTree={setDevicesPresentInTree}
              devicesPresentInTree={devicesPresentInTree}
              onItemClick={onItemClick}
              setDeviceDetailsModal={setDeviceDetailsModal} />
          ) : null
        }
      </TreeItem>
    </div>
  );
};

export default HierarchyItem;

