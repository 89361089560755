import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DragHandleSharpIcon from '@mui/icons-material/DragHandleSharp';

import { Draggable, DropResult } from 'react-beautiful-dnd';
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder,
} from 'react-beautiful-dnd';
import { Contact } from 'types/custom';
import { CustomCardHeader } from './CustomComponents';
import { convertLocalHourToUTC, convertUTCHourToLocal } from 'utils';

function not(a: any[], b: any[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: any[], b: any[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: any[], b: any[]) {
    return [...a, ...not(b, a)];
}

export type DraggableListProps = {
    items: Contact[];
    onDragEnd: OnDragEndResponder;
};

const TransferList = ({
    allItems,
    selectedItems,
    itemType,
    stageDuration,
    setStageDuration,
    flowType,
    setStages,
    setContactsObj,
}: {
    allItems: any;
    selectedItems: any;
    itemType: string;
    stageDuration?: number;
    setStageDuration?: any;
    flowType: string;
    setStages?: any;
    setContactsObj?: any;
}) => {
    const [checked, setChecked] = useState<any[]>([]);
    const [left, setLeft] = useState<any>();
    const [right, setRight] = useState<any>();

    //filter contacts to show unique values in Notification groups
    const filterAllContacts = () => {
        const groupContactsArr =
            flowType === 'update' ? selectedItems['contacts'] : [];

        const groupContactsLocalTime = groupContactsArr?.map(
            (contact: any) => ({
                ...contact,
                onCall: {
                    endHour: convertUTCHourToLocal(
                        contact.onCall.endHour
                    ).toString(),
                    startHour: convertUTCHourToLocal(
                        contact.onCall.startHour
                    ).toString(),
                },
            })
        );

        setRight(groupContactsLocalTime);

        if (allItems) {
            const allContactsArr = allItems?.map((contact: any) => ({
                alias: contact.alias,
                onCall: {
                    endHour: convertUTCHourToLocal(
                        contact.onCall.end
                    ).toString(),
                    startHour: convertUTCHourToLocal(
                        contact.onCall.start
                    ).toString(),
                },
            }));

            const allContactsFiltered = allContactsArr.filter(
                (contact: any) => {
                    return groupContactsArr.every((item: any) => {
                        return contact.alias !== item.alias;
                    });
                }
            );

            setLeft(allContactsFiltered);
        }
    };

    //filter groups to show unique values in Escalation plans
    const filterAllGroups = () => {
        const plansGroupName =
            flowType === 'update' ? selectedItems['stages'] : [];
        setRight(plansGroupName);

        if (allItems) {
            const allGroupsName = allItems.map((group: any) => ({
                stage: 1,
                notificationGroup: group.name,
                stageDuration: 0,
            }));

            const allGroupsFiltered = allGroupsName.filter((group: any) => {
                return plansGroupName.every((plan: any) => {
                    return group.notificationGroup !== plan.notificationGroup;
                });
            });

            setLeft(allGroupsFiltered);
        }
    };

    useEffect(() => {
        if (itemType === 'plans') {
            setStages(right);
        } else if (itemType === 'groups') {
            const contactsWithUTC = right?.map((contact: any) => ({
                ...contact,
                onCall: {
                    endHour: convertLocalHourToUTC(contact.onCall.endHour),
                    startHour: convertLocalHourToUTC(contact.onCall.startHour),
                },
            }));

            setContactsObj(contactsWithUTC);
        }
    }, [right]);

    useEffect(() => {
        if (itemType === 'groups') {
            filterAllContacts();
        } else {
            filterAllGroups();
        }
    }, [selectedItems, flowType]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: any[]) =>
        intersection(checked, items).length;

    const handleToggleAll = (items: any[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        if (itemType === 'plans') {
            leftChecked.map(
                (checked) =>
                (checked.stageDuration = stageDuration
                    ? +stageDuration
                    : 0)
            );

            setStageDuration('');
        }

        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        rightChecked[0].stageDuration = 0;
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title: string, items: Contact[]) => (
        <Card>
            <CustomCardHeader
                title={title}
                items={items}
                numberOfChecked={numberOfChecked}
                handleToggleAll={handleToggleAll}
            />

            <Divider />
            <List
                sx={{
                    minHeight: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                    borderBlockWidth: '2px',
                }}
                component='div'
                role='list'
            >
                {items.map((value: any, i) => {
                    const labelId = `transfer-list-all-item-${value}-label`;
                    const startHours = value.onCall?.startHour;
                    const endHours = value.onCall?.endHour;

                    return (
                        <ListItem
                            key={
                                itemType === 'groups'
                                    ? value.alias
                                    : value.notificationGroup
                            }
                            role='listitem'
                            button
                            onClick={handleToggle(value)}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>

                            <ListItemText
                                id={`${labelId}-col1`}
                                primary={
                                    itemType === 'groups'
                                        ? `${value.alias}`
                                        : `${value.notificationGroup}`
                                }
                                secondary={
                                    itemType === 'groups'
                                        ? `Hours: ${startHours < 10
                                            ? '0' + startHours
                                            : startHours
                                        } - ${endHours < 10
                                            ? '0' + endHours
                                            : endHours
                                        }`
                                        : `Stage Duration: ${value.stageDuration} minute(s)`
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const DraggableListItem = ({ item: value, index }: any) => {
        return (
            <Draggable
                draggableId={value?.notificationGroup || value?.alias}
                index={index}
            >
                {(provided, snapshot) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={value?.notificationGroup}
                        >
                            <ListItem
                                key={
                                    itemType === 'groups'
                                        ? value.alias
                                        : value.notificationGroup
                                }
                                role='listitem'
                                button
                                onClick={handleToggle(value)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    cursor: 'grabbing',
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={`${value?.notificationGroup}`}
                                    secondary={`Stage Duration: ${value?.stageDuration} minute(s)`}
                                />
                                <ListItemIcon
                                    style={{ justifyContent: 'flex-end' }}
                                >
                                    <DragHandleSharpIcon />
                                </ListItemIcon>
                            </ListItem>
                        </div>
                    );
                }}
            </Draggable>
        );
    };

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;
        setRight(reorder(right, source.index, destination.index));
    };

    const CustomPlansList = ({ items, onDragEnd }: DraggableListProps) => (
        <Card>
            <CustomCardHeader
                title='Chosen'
                items={items}
                numberOfChecked={numberOfChecked}
                handleToggleAll={handleToggleAll}
            />

            <Divider />

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable-list' direction='vertical'>
                    {(provided) => {
                        return (
                            <List
                                sx={{
                                    height: 'auto',
                                    minHeight: 230,
                                    bgcolor: 'background.paper',
                                }}
                                component='div'
                                role='list'
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {items.map((item: any, index) => {
                                    return (
                                        <DraggableListItem
                                            item={item}
                                            index={index}
                                            key={item.notificationGroup}
                                        />
                                    );
                                })}
                                <div>{provided.placeholder}</div>
                            </List>
                        );
                    }}
                </Droppable>
            </DragDropContext>
        </Card>
    );

    return (
        <Grid
            container
            spacing={3}
            justifyContent='flex-start'
            alignItems='center'
        >
            {left && right && (
                <>
                    <Grid item minWidth={300}>
                        {customList('Choices', left)}
                    </Grid>

                    <Grid item>
                        <Grid container direction='column' alignItems='center'>
                            <Button
                                sx={{ my: 1.5 }}
                                variant='contained'
                                size='medium'
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label='move selected right'
                                color='primary'
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 1.5 }}
                                variant='contained'
                                size='medium'
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label='move selected left'
                            >
                                &lt;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item minWidth={300}>
                        {itemType === 'groups' ? (
                            customList('Chosen', right)
                        ) : (
                            <CustomPlansList
                                items={right}
                                onDragEnd={onDragEnd}
                            />
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default TransferList;
