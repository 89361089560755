export const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: (item: any) => item.name,
        sortingField: 'name',
    },
    {
        id: 'privilege',
        header: 'Privilege',
        cell: (item: any) => item.privilege,
        sortingField: 'privilege',
    },
];
