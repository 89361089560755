import { ChartData, DeviceTelemetry } from "./types";

import { DateRangePickerProps } from '@cloudscape-design/components';
import { relativeTimeMap, timeUnitMap } from 'components/date-time-picker/const';

export const prepareChartData = (
  deviceTelemetry: DeviceTelemetry[][],
): ChartData[] => {
  return deviceTelemetry.map((item, id) => ({
    id: id.toString(),
    data: item.map(([x, y, color, friendlyName]) => ({
      x: new Date(parseInt(x)),
      y: Number(y),
      color,
      friendlyName,
    })),
  }));
};

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

type DateRangeLabel = {
  start: Date
  end: Date
  label: string
}

export function groupDaysByWeek(start: Date, end: Date): DateRangeLabel[] {
    const weeks: DateRangeLabel[] = [];

    let current = new Date(start);
    let weekStart = new Date(start);
    let weekEnd = new Date(start);
    weekEnd.setDate(weekEnd.getDate() + (7 - weekEnd.getDay()));
    weekEnd.setHours(23, 59, 59, 999);

    while (current <= end) {
        if (current > weekEnd) {
            weekStart = new Date(current.toISOString());
            weekEnd.setDate(weekEnd.getDate() + 7);
        }

        const weekLabel = `${weekStart.getDate()} ${weekStart.toLocaleString('default', { month: 'short' })} - ${weekEnd.getDate()} ${weekEnd.toLocaleString('default', { month: 'short' })}`;
        let weekRange = weeks.find(w => w.label === weekLabel);

        if (!weekRange) {
            weekRange = {
                start: new Date(weekStart),
                end: new Date(weekEnd),
                label: weekLabel
            };
            weeks.push(weekRange);
        }

        current.setDate(current.getDate() + 1);
    }

    return weeks;
}

const getBucket = (date: Date, ranges: DateRangeLabel[]) => {
  let label = "";
  date = new Date(date)
  for (const range of ranges) {
    range.end.setHours(23, 59, 59, 999);
    if (date >= range.start && date <= range.end) {
      label = range.label;
      break;
    }
  }
  return label;
}

export function createWeeklySeries(
  data: ChartData,
  startDay: Date,
  endDay: Date,
) {
  const weekRanges = groupDaysByWeek(startDay, endDay);


  const dataPoints = data.data.map((point) => ({
    data: {
      date: new Date(point.x),
      x: getBucket(point.x, weekRanges),
      y: point.y,
    },
  }));

  return daysOfWeek.map((day, index) => {
    const dataPointsForCurrentDay = dataPoints
      .filter((x) => {
        const adjustedDayIndex = (x.data.date.getDay() + 6) % 7;

        return adjustedDayIndex === index;
      })
      .map((x) => x.data);

    const averagePerWeek = weekRanges.map((range) => {
      const weekData = dataPointsForCurrentDay.filter((point) => point.x === range.label)
      const average =
        weekData.length > 0
          ? weekData.reduce((sum, value) => sum + value.y, 0) / weekData.length
          : null

      return {
        x: range.label,
        y: average,
      };
    });

    return {
      name: day,
      data: averagePerWeek.map((point) => ({
        x: point.x,
        y: point.y,
      })),
    };
  });
}

export function createDailySeries(
  data: ChartData,
  startDate: Date,
  endDate: Date,
  minValue?: number,
  maxValue?: number
) {
  const hoursOfDay = Array.from({ length: 24 }, (_, i) => `${i}:00`);

  const seriesData = daysOfWeek.map((day, index) => ({
    name: day,
    data: hoursOfDay.map((hour) => {
      const [hourValue] = hour.split(":").map(Number);
      const dayValues: number[] = [];
      const dateLabels: string[] = [];

      data.data.forEach((d) => {
        const date = new Date(d.x);
        const adjustedDayIndex = (date.getDay() + 6) % 7;

        const endDatePlus24 = new Date(endDate);
        endDatePlus24.setHours(23, 59, 59, 999);

        if (
          date.getTime() >= startDate.getTime() &&
          date.getTime() <= endDatePlus24.getTime() &&
          adjustedDayIndex === index &&
          date.getHours() === hourValue
        ) {
          dayValues.push(d.y);
          dateLabels.push(date.toString());
        }
      });

      let averageValue =
        dayValues.length > 0
          ? dayValues.reduce((sum, value) => sum + value, 0) / dayValues.length
          : null;

      if (
        averageValue !== null &&
        ((minValue !== undefined && averageValue < minValue) ||
          (maxValue !== undefined && averageValue > maxValue))
      ) {
        averageValue = null;
      }

      return { x: hour, y: averageValue, dateLabels };
    }),
  }));

  return seriesData;
}

export const parseDates = (dateRange: DateRangePickerProps.Value) => {
  if (dateRange?.type === 'absolute') {
    return [new Date(dateRange.startDate), new Date(dateRange?.endDate)];
  }

  const relativeTime = relativeTimeMap.get(dateRange?.key!);

  if (!dateRange?.key && dateRange?.unit) {
    const timeUnit = timeUnitMap.get(dateRange.unit);
    return [new Date(Date.now() - dateRange.amount! * timeUnit!), new Date()];
  };

  return [new Date(Date.now() - relativeTime!), new Date()];
};

