import { Spinner as GlobalSpinner } from '@cloudscape-design/components';

const Spinner = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <GlobalSpinner size='large' />
        </div>
    );
};

export default Spinner;
