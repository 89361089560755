import { TableProps } from "@cloudscape-design/components";
import StatusIndicator, { StatusIndicatorProps } from "@cloudscape-design/components/status-indicator";

import { toTitleCase } from "utils";
import { Asset, DeviceState } from "types/custom";

export const COLUMN_DEFINITIONS: readonly TableProps.ColumnDefinition<Asset>[] = [
    {
        id: 'name',
        header: 'Name',
        cell: (item: Asset) => item.name,
        sortingField: 'name',
    },
    {
        id: 'friendlyName',
        header: 'Friendly Name',
        cell: (item: Asset) => item.friendlyName,
        sortingField: 'friendlyName',
    },
    {
        id: 'deviceState',
        header: 'State',
        cell: (item: Asset) =>
            <StatusIndicator type={statusIndicatorMap(item.deviceState)}>
                {toTitleCase(item.deviceState)}
            </StatusIndicator>,
        sortingField: 'deviceState',
    },
    {
        id: 'deviceTypeId',
        header: 'Device Type',
        cell: (item: Asset) => item.deviceTypeId,
        sortingField: 'deviceTypeId',
    },
    {
        id: 'physicalDeviceId',
        header: 'Physical Device ID',
        cell: (item: Asset) => item.physicalDeviceId,
        sortingField: 'physicalDeviceId',
    },
    {
        id: 'description',
        header: 'Description',
        cell: (item: Asset) => item.description,
        sortingField: 'description',
    },
    {
        id: 'lookupId',
        header: 'Lookup ID',
        cell: (item: Asset) => item.lookupId,
        sortingField: 'lookupId',
    },
    {
        id: 'invoiceId',
        header: 'Invoice ID',
        cell: (item: Asset) => item.invoiceId,
        sortingField: 'invoiceId',
    },
    {
        id: 'friendlyCurrentPath',
        header: 'Friendly Current Path',
        cell: (item: Asset) => item.friendlyCurrentPath,
        sortingField: 'friendlyCurrentPath',
    },
    {
        id: 'lastUpdated',
        header: 'Last Updated',
        cell: (item: Asset) => {
            const date = new Date(item.updatedAt * 1000).toLocaleString();
            return date === 'Invalid Date' ? 'Not recorded' : date;
        },
        sortingField: 'lastUpdated',
    },
];

export const buttonDropdownItems = (selectedDevices: Asset[]) => {
    const deviceState = selectedDevices[0]?.deviceState?.toLowerCase();

    const canClaim = deviceState === DeviceState.provisioned;
    const canInstall = deviceState === DeviceState.claimed || deviceState === DeviceState.disabled;
    const canDisable = selectedDevices.length > 0 && selectedDevices.every((device: Asset) =>
        device.deviceState === DeviceState.inService ||
        device.deviceState === DeviceState.claimed);
    const canDelete = deviceState && deviceState !== DeviceState.provisioned;
    const canMove = deviceState === DeviceState.inService;
    const canUpdate = !!deviceState;

    return [
        {
            text: 'Claim',
            id: 'claim',
            disabled: !canClaim,
        },
        {
            text: 'Install',
            id: 'install',
            disabled: !canInstall,
        },
        {
            text: 'Move',
            id: 'move',
            disabled: !canMove,
        },
        {
            text: 'Update',
            id: 'edit',
            disabled: !canUpdate,
        },
        {
            text: 'Disable',
            id: 'disable',
            disabled: !canDisable,
        },
        {
            text: 'Delete',
            id: 'delete',
            disabled: !canDelete,
        },
    ];
};

export const statusIndicatorMap = (state: DeviceState): StatusIndicatorProps.Type | undefined => {
    switch (state) {
        case DeviceState.provisioned:
            return 'info';
        case DeviceState.claimed:
            return 'info';
        case DeviceState.inService:
            return 'success';
        case DeviceState.disabled:
            return 'warning';
        case DeviceState.deleted:
            return 'stopped';
        default:
            return undefined;
    }
}