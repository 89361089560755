import { Popover, KeyValuePairs } from "@cloudscape-design/components";
import AlertStatusBadge from "components/alert-status/AlertStatusBadge";
import { snakeCaseToNormalCase } from "components/device-card/utils";
import { AssetState } from "types/custom";

function StatePopover({ measurement, state, children, customTrigger = false }: {
	measurement: string,
	state: AssetState,
	children: React.ReactNode,
	customTrigger?: boolean
}) {
	return (
		<Popover
		header={snakeCaseToNormalCase(measurement)}
		size="large"
		triggerType={customTrigger ? "custom" : "text"}
		content={
			<KeyValuePairs
			columns={2}
				items={[
				{ label: "Value", value: state.value },
				{ label: "Previous Value", value: state.prevValue ?? '-' },
				{ label: "Alert Code", value: (<AlertStatusBadge code={state.alertCode} />) },
				{ label: "Previous Alert Code", value: (<AlertStatusBadge code={state.prevAlertCode} noColor={true} />) },
				{ label: "Updated At", value: new Date(state.updatedAt).toLocaleString(undefined, {timeZoneName: "shortOffset"}) },
				{ label: "Previously Updated At", value: state.prevUpdatedAt ? new Date(state.prevUpdatedAt).toLocaleString(undefined, {timeZoneName: "shortOffset"}): '-' },
				]}
			/>
		}
		>
			{children}
		</Popover>
	)
}

export default StatePopover;