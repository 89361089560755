import { useEffect, useState } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween, Spinner } from '@cloudscape-design/components';

import useFetch from 'hooks/useFetch';
import { incidentManagerAPI } from 'api';
import { API_URL_PATH_IM_CONTACTS } from 'constants/urls';
import ListItemComp from 'components/incident-manager/ListItem';
import { useIncidentManagerContext } from 'pages/incident-manager/IncidentManagerPage';

type ContactListProps = {
    setIsUpdatingContact: (state: boolean) => void;
};

const ContactsList = ({ setIsUpdatingContact }: ContactListProps) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [personToDelete, setPersonToDelete] = useState<any>();
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const {
        allContacts,
        setSelectedContact,
        contactsRefetch,
        contactsLoading
    } = useIncidentManagerContext();

    const { loading: updateContactLoading, fetchData: updateContactActive } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'PATCH',
        },
        { manual: true }
    );

    const { loading: deleteContactLoading, fetchData: deleteContact } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'DELETE',
        },
        { manual: true }
    );

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
        setIsUpdatingContact(true);
    };

    const handleDisableContact = (event: React.ChangeEvent<HTMLInputElement>, person: any) => {
        updateContactActive(`${API_URL_PATH_IM_CONTACTS}/${person.alias}`, {
            active: !person.active,
        });
    };

    const showDeleteModal = (person: any) => {
        setPersonToDelete(person);
        setDeleteModalVisible(true);
    }

    const handleRemoveContact = (alias: string) => {
        deleteContact(`${API_URL_PATH_IM_CONTACTS}/${alias}`);
        setDeleteModalVisible(false);
    };

    useEffect(() => {
        if (allContacts) {
            setSelectedContact(allContacts[0]);
            setSelectedIndex(0);
        }
    }, [allContacts]);

    useEffect(() => {
        if (!updateContactLoading || !deleteContactLoading) {
            contactsRefetch();
        }
    }, [updateContactLoading, deleteContactLoading, contactsRefetch]);

    return (
        <div>
            {contactsLoading && <Spinner />}
            {(updateContactLoading || deleteContactLoading) && <Spinner />}
            {allContacts && allContacts.length === 0 && (
                <Alert>No contacts found</Alert>
            )}

            <Modal
                onDismiss={() => setDeleteModalVisible(false)}
                header='Delete Contact'
                visible={deleteModalVisible}
                closeAriaLabel='Close modal'
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={() => setDeleteModalVisible(false)}>
                                Cancel
                            </Button>
                            <Button variant='primary' onClick={() => handleRemoveContact(personToDelete?.alias)}>
                                Delete
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <div>
                    Are you sure you want to delete {" " + personToDelete?.alias} permanently?
                    <i>You can't undo this action.</i>
                </div>
            </Modal>

            <ListItemComp
                allItems={allContacts}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                setSelectedItem={setSelectedContact}
                module='contacts'
                disableContact={handleDisableContact}
                isLoading={updateContactLoading || deleteContactLoading}
                removeContact={showDeleteModal}
            />
        </div>
    );
};

export default ContactsList;
