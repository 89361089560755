import { Asset, HierarchyNode, MinimalNode } from "types/custom";

export const makeNodeFromSensor = (sensor: Asset): HierarchyNode => ({
  id: sensor.name,
  name: sensor.friendlyName || sensor.name,
  assetList: [],
  children: [],
  isDevice: true,
});

export const findSourceNodeAndParentInTree = (node: HierarchyNode, tree: HierarchyNode): { node: HierarchyNode | null, parent: HierarchyNode | null; } => {
  if (tree.id === node.id) {
    return { node: tree, parent: null };
  }

  if (tree.children) {
    for (let i = 0; i < tree.children.length; i++) {
      const found = findSourceNodeAndParentInTree(node, tree.children[i]);
      if (found.node) {
        return { node: found.node, parent: found.parent ? found.parent : tree };
      }
    }
  }

  return { node: null, parent: null };
};

export const isInHierarchy = (node: HierarchyNode, tree: HierarchyNode): boolean => {
  if (tree.id === node.id) {
    return true;
  }

  if (!tree.children) {
    return false;
  }

  return tree.children.some((child) => isInHierarchy(node, child));
};

export const deleteNode = async (node: HierarchyNode, parent: HierarchyNode): Promise<boolean> => {
  let found = false;

  if (parent.children) {
    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].id === node.id) {
        parent.children = parent.children.filter((child) => child.id !== node.id);
        found = true;
        break;
      } else {
        deleteNode(node, parent.children[i]);
      }
    }
  }

  return found;
};

// Warning: re-attach operation is done on the same reference
export const reAttachNode = (source: HierarchyNode, destination: HierarchyNode, tree: HierarchyNode): HierarchyNode | null => {
  if (source.id === destination.id) {
    return null;
  }

  const isSourceParentOfDestination = isInHierarchy(destination, source);

  if (isSourceParentOfDestination) {
    throw new Error('Cannot move a tag to its child');
  }

  const { parent: sourceParent } = findSourceNodeAndParentInTree(source, tree);
  if (!sourceParent || destination.id === sourceParent.id) {
    return null;
  }

  sourceParent.children = sourceParent?.children?.filter((child) => child.id !== source.id) || [];
  sourceParent.assetList = sourceParent?.assetList?.filter((child) => child !== source.name) || [];

  destination.children?.push(source);
  destination.assetList?.push(source.name!);

  return tree;
};

export const toMinimalNode = (node: HierarchyNode): MinimalNode => ({
  tagId: node.id,
  children: !node.isDevice ? node.children?.filter(x => !x.isDevice)?.map(toMinimalNode) : undefined,
});
