import { Container } from '@cloudscape-design/components';
import CreateDeviceGroup from 'components/device-group-manager/CreateDeviceGroup';

const CreateDeviceGroupPage = () => {
    return (
        <Container>
            <CreateDeviceGroup />
        </Container>
    );
};

export default CreateDeviceGroupPage;
