import { Auth } from "aws-amplify";
import { useMutation } from "@tanstack/react-query";

import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

type UseMutationWithReactQueryProps<TData> = {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  onSuccess?: (data: TData) => void;
  onError?: (error: Error) => void;
  api: AxiosInstance,
};

const useMutationWithReactQuery = <TResponseBody, TRequestBody>({ url, method, onSuccess, onError, api }: UseMutationWithReactQueryProps<AxiosResponse<TResponseBody>>) => {
  const { mutate, mutateAsync, data, error, status, isPending, isError, isSuccess } = useMutation({
    mutationFn: async (data: TRequestBody & AxiosRequestConfig<TRequestBody>) => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }

      return api.request<TResponseBody>({
        method,
        url,
        data,
        headers,
      });
    },
    onSuccess: (data) => onSuccess?.(data),
    onError: (error) => onError?.(error)
  });

  return { mutate, mutateAsync, data, error, status, isPending, isError, isSuccess };
};

export default useMutationWithReactQuery;