export const colors = [
  "#076D7E",
  "#E18600",
  "#C5C3EE",
  "#A3E5EE",
  "#FFE667",
  "#5D4495",
  "#F58A68",
  "#0050AE",
  "#52514A",
  "#3D5665",
];

export const colorProgressionMap = {
  "#076D7E": ["#076D7E", "#21889A", "#3BA3B6", "#55BECF", "#6FD9E8"],
  "#E18600": ["#E18600", "#E59E33", "#E9B566", "#EEC099", "#F2DACC"],
  "#C5C3EE": ["#C5C3EE", "#D2D1F1", "#DFDFF4", "#ECECF7", "#F9F9FA"],
  "#A3E5EE": ["#A3E5EE", "#B3EBF1", "#C3F1F4", "#D3F7F7", "#E3FDFB"],
  "#FFE667": ["#FFE667", "#FFE982", "#FFED9D", "#FFF0B8", "#FFF4D3"],
  "#5D4495": ["#5D4495", "#755FA7", "#8C7AB9", "#A496CB", "#BCB1DD"],
  "#F58A68": ["#F58A68", "#F7A188", "#F9B9A9", "#FBD1C9", "#FDE9E9"],
  "#0050AE": ["#0050AE", "#2369C0", "#4A83D2", "#709CE4", "#97B5F6"],
  "#52514A": ["#52514A", "#6B6A63", "#84837C", "#9E9C95", "#B7B5AE"],
  "#3D5665": ["#3D5665", "#5A6F7C", "#778894", "#94A1AB", "#B1BBC2"]
};
