import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, ColumnLayout } from '@cloudscape-design/components';

import { usePageLayoutContext } from 'components/common/layout';
import RolesTable from 'components/role-manager/RolesTable';
import useFetch from 'hooks/useFetch';
import RolesTabs from 'components/role-manager/RolesTabs';
import Spinner from 'components/spinner/Spinner';

import { UserRolesProps } from 'types/custom';
import { API_URL_PATH_UM_ROLES } from 'constants/urls';
import { userManagerAPI } from 'api';

const UserManagerContext = createContext({
    allItems: [] as any[],
    loading: false,
    error: '',
    setSelectedItem: (state: UserRolesProps[]) => { },
    selectedItem: [] as UserRolesProps[] | undefined,
    refetch: () => { },
    activeTabId: '',
    setActiveTabId: (state: string) => { },
});

export const useUserManagerContext = () => useContext(UserManagerContext);

const RolesListPage = () => {
    const [selectedItem, setSelectedItem] = useState<UserRolesProps[]>([]);
    const [allItems, setAllItems] = useState<any[]>([]);
    const [activeTabId, setActiveTabId] = useState<string>('details');

    const { setNotification } = usePageLayoutContext();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location?.state?.action) {
            setNotification([
                {
                    type: 'success',
                    content: location.state.message,
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setNotification([]),
                    id: `flash-${location.state.action}`,
                },
            ]);

            navigate(location.pathname, {});
        }
    }, [location?.state?.action]);

    const { response, error, loading, status, refetch } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'GET',
            url: API_URL_PATH_UM_ROLES,
        },
        { manual: false }
    );

    useEffect(() => {
        if (status === 200) {
            setAllItems(response?.items || []);
        }
    }, [status, response]);

    return (
        <UserManagerContext.Provider
            value={{
                loading,
                allItems,
                error,
                setSelectedItem,
                selectedItem,
                refetch,
                activeTabId,
                setActiveTabId,
            }}
        >
            <ColumnLayout variant='text-grid'>
                {loading && (
                    <>
                        <br></br>
                        <Spinner />
                    </>
                )}
                {response && <RolesTable />}
            </ColumnLayout>

            {response && selectedItem![0]?.name !== undefined && (
                <>
                    <Box margin={{ top: 'xxxl' }}></Box>
                    <ColumnLayout variant='text-grid'>
                        <RolesTabs />
                    </ColumnLayout>
                </>
            )}
        </UserManagerContext.Provider>
    );
};

export default RolesListPage;
