import { Popover, SpaceBetween, SegmentedControl, Textarea, Button } from "@cloudscape-design/components";
import { incidentManagerAPI } from "api";
import { usePageLayoutContext } from "components/common/layout";
import { API_URL_PATH_IM_INCIDENTS } from "constants/urls";
import useFetch from "hooks/useFetch";
import { useState } from "react";
import { Incident, IncidentActivity, IncidentStatus } from "types/custom";

function IncidentAddActivity({
    incidents,
    refetchIncidents,
	loading,
}: {
    incidents: Incident[];
    refetchIncidents: () => void;
	loading?: boolean;
}) {
	const [newActivityValue, setNewActivityValue] = useState('');
	const [newActivityType, setNewActivityType] = useState('');
	
	const {
        fetchData: addNewActivity,
        loading: isAddingNewActivity,
    } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'PATCH',
            url: `${API_URL_PATH_IM_INCIDENTS}/${incidents.map((x) => x.id).join(',')}`,
            data: {
                type: newActivityType,
                value: newActivityValue,
            },
        },
        { manual: true }
    );

	const { setNotification } = usePageLayoutContext();

    const handleNewActivity = async () => {
        const result = await addNewActivity();
        if (result.status >= 200 && result.status < 300) {
            refetchIncidents();
            setNotification([
                {
                    type: 'success',
                    content: 'Successfully added event to incident',
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setNotification([]),
                    id: 'flash-resolve-incident',
                },
            ]);
        } else {
            setNotification([
                {
                    type: 'error',
                    content: result.data.message || 'Bad request',
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setNotification([]),
                    id: 'flash-resolve-incident-error',
                },
            ]);
        }
        setNewActivityType('');
        setNewActivityValue('');
    }

	const isResolved = incidents?.some(x => x.status === IncidentStatus.RESOLVED || x.status === IncidentStatus.FORWARDED);
	const isDisabled = isAddingNewActivity || loading || !incidents.length || isResolved;

	return (
		<Popover
		size='large'
		header='Add Event'
		dismissButton={true}
		triggerType='custom'
		content={
			<SpaceBetween size='xs'>
				<SegmentedControl
				selectedId={newActivityType || null}
				onChange={({ detail }) => {
					setNewActivityType(detail.selectedId);
				}}
				options={[
					{
						text: 'Comment',
						id: IncidentActivity.COMMENT,
						disabled: isDisabled,
					},
					{
						text: 'Acknowledge',
						id: IncidentActivity.ACKNOWLEDGE,
						disabled: isDisabled,
					},
					{
						text: 'Resolve',
						id: IncidentActivity.RESOLUTION,
						disabled: isDisabled,
					}
				]}
				/>
				<Textarea
					value={newActivityValue}
					onChange={(e) => setNewActivityValue(e.detail.value)}
					placeholder='Add details here'
					disabled={isDisabled}
				/>
				<Button
				variant='primary'
				onClick={handleNewActivity}
				loading={isAddingNewActivity || loading}
				disabled={isResolved || !newActivityType || !newActivityValue}
				disabledReason='Incident is resolved / forwarded or fields are empty'
				>
					Add
				</Button>                
			</SpaceBetween>
		}>
			<Button
			loading={isAddingNewActivity}
			disabled={isDisabled}
			disabledReason='Incident is resolved or forwarded'
			>Add Event</Button>
		</Popover>
	)
}

export default IncidentAddActivity;