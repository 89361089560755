import { ColumnLayout, Box, SpaceBetween } from '@cloudscape-design/components';
import { useDeviceGroupsContext } from 'pages/device-group-manager/DeviceGroupListPage';

const DeviceGroupDetails = () => {
    const { selectedGroup } = useDeviceGroupsContext();
    return (
        <ColumnLayout columns={3} variant='text-grid'>
            <SpaceBetween size='l'>
                <div>
                    <Box variant='awsui-key-label'>Device absolute path</Box>
                    <div>
                        {selectedGroup![0]?.assetAbsPaths?.join(', ') || '-'}
                    </div>
                </div>
                <div>
                    <Box variant='awsui-key-label'>Device path</Box>
                    <div>
                        {selectedGroup![0]?.assetPaths?.join(', ') || '-'}
                    </div>
                </div>
            </SpaceBetween>
            <SpaceBetween size='l'>
                <div>
                    <Box variant='awsui-key-label'>Devices</Box>
                    <div style={{ overflow: 'auto', height: '220px' }}>
                        {selectedGroup![0]?.assets?.map((asset) => (
                            <div key={asset.assetId}>{asset.assetId}</div>
                        ))}
                    </div>
                </div>
            </SpaceBetween>
            <SpaceBetween size='l'>
                <div>
                    <Box variant='awsui-key-label'>Roles</Box>
                    <div style={{ overflow: 'auto', height: '220px' }}>
                        {selectedGroup![0]?.userRoleIds?.join(', ') || '-'}
                    </div>
                </div>
            </SpaceBetween>
        </ColumnLayout>
    );
};

export default DeviceGroupDetails;
