import { ContentLayout, Grid, Header, SpaceBetween } from '@cloudscape-design/components';
import DateTimePicker from 'components/date-time-picker';
import DashboardProvider from 'providers/DashboardProvider';
import DashboardPage from './DashboardPage';
import colors from '../../theme/colors';
import RolePicker from 'components/role-picker/RolePicker';

const Dashboard = () => {
    return (
        <DashboardProvider>
            <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={
                <Header variant="h1" actions={<SpaceBetween size='xs' direction='horizontal'>
                    <RolePicker/>
                    <DateTimePicker />
                </SpaceBetween>}>Dashboard</Header>
            }>
                <Grid gridDefinition={[{ colspan: 12 }]}>
                    <DashboardPage />
                </Grid>
            </ContentLayout>
        </DashboardProvider>
    );
};

export default Dashboard;
