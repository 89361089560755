import { deviceManagerAPI } from 'api';
import {
  API_URL_PATH_DM_DEVICES,
} from 'constants/urls';
import { Auth } from 'aws-amplify';
import { Asset } from 'types/custom';

export const getDevices = async (deviceIds: string[]) => {

  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  const devices = await deviceManagerAPI.get<Asset[]>(
    `${API_URL_PATH_DM_DEVICES}/${deviceIds.join(',')}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return devices.data;
};
