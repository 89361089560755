import { Alert, Tabs } from '@cloudscape-design/components';

import IncidentsTable from '../DeviceIncidents/IncidentsTable';
import AlertsTable from '../DeviceAlertMgr/AlertsTable';
import DeviceDetails from '../DeviceDetails';
import { Asset } from 'types/custom';

type DeviceTabsProps = {
    selectedDevices: Asset | Asset[] | undefined;
};

const DeviceTabs = ({ selectedDevices }: DeviceTabsProps) => {
    if (!selectedDevices || (Array.isArray(selectedDevices) && selectedDevices.length === 0)) {
        return (
            <Alert type="warning">No device selected, please select one device only to see details</Alert>
        );
    }

    const device = Array.isArray(selectedDevices) ? selectedDevices[0] : selectedDevices;

    return (
        <Tabs
            tabs={[
                {
                    label: 'Details',
                    id: 'details',
                    content: <DeviceDetails selectedDevices={device} />,
                },
                {
                    label: 'Alert Rules',
                    id: 'alert-rules',
                    content: <AlertsTable selectedDevices={device} />,
                },
                {
                    label: 'Incidents',
                    id: 'incidents',
                    content: <IncidentsTable selectedDevices={device} />,
                },
            ]}
            variant='container'
        />
    );
};

export default DeviceTabs;
