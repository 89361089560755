import TopNavigation from '@cloudscape-design/components/top-navigation';
import useAuth from 'hooks/useAuth';
import { Box } from '@cloudscape-design/components';

const TopNav = () => {
    const { user, privileges, logout } = useAuth();

    const handleDropdownClick = (event: any) => {
        event.preventDefault();

        if (event.detail.id === 'signout') {
            logout();
        }
    };

    return (
        <TopNavigation
            identity={{
                href: '/',
                logo: {
                    src: '/logo.svg',
                    alt: 'Home',
                },
            }}
            search={<div style={{ textAlign: 'center' }}><Box variant='strong'>SVAN {window['runtimeConfig'].envTitle}</Box></div>}
            utilities={[
                {
                    type: 'menu-dropdown',
                    text: user,
                    description: `Privileges: ${privileges?.toUpperCase()}`,
                    iconName: 'user-profile',
                    items: [
                        {
                            id: 'signout',
                            text: 'Sign out',
                        },
                    ],
                    onItemClick: handleDropdownClick,
                },
            ]}
            i18nStrings={{
                searchIconAriaLabel: 'Search',
                searchDismissIconAriaLabel: 'Close search',
                overflowMenuTriggerText: 'More',
                overflowMenuTitleText: 'All',
                overflowMenuBackIconAriaLabel: 'Back',
                overflowMenuDismissIconAriaLabel: 'Close menu',
            }}
        />
    );
};

export default TopNav;
