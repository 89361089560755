import { ChartData } from "components/device-chart/types";
import ApexCharts from "react-apexcharts";
import { snakeCaseToNormalCase } from "../../device-card/utils";

type LineChartProps = {
  measurement: string;
  data: ChartData[];
  zoomRange: {
    min: number | undefined;
    max: number | undefined;
  };
  setZoomRange: (range: {
    min: number | undefined;
    max: number | undefined;
  }) => void;
};

const stepSizeBasedOnMeasurement = new Map([
  ['outage', 1],
  ['state', 1],
  ['door_status', 1],
]);

const CustomLineChart = ({ measurement, data, zoomRange, setZoomRange }: LineChartProps) => {
  const devicesWithSelectedMeasurementData = data.filter((device) => device.data.length > 0);

  return (
    <ApexCharts
      height={200}
      options={{
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        title: {
          text: snakeCaseToNormalCase(measurement),
          align: 'left',
        },
        chart: {
          type: 'line',
          animations: {
            enabled: false,
          },
          events: {
            zoomed(_ctx, { xaxis }) {
              if (xaxis.min === zoomRange.min && xaxis.max === zoomRange.max) {
                return setZoomRange({
                  min: undefined,
                  max: undefined,
                });
              }

              setZoomRange({
                min: xaxis.min,
                max: xaxis.max,
              });
            },
          },
          toolbar: {
            tools: {
              download: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: true,
            },
            export: {
              csv: {
                filename: `${devicesWithSelectedMeasurementData.map((device) => device.data[0].friendlyName).join('-')}-${measurement}`,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                categoryFormatter(timestamp) {
                  return new Date(timestamp!).toLocaleString();
                },
              },
              svg: {
                filename: `${devicesWithSelectedMeasurementData.map((device) => device.data[0].friendlyName).join('-')}-${measurement}`,
              },
              png: {
                filename: `${devicesWithSelectedMeasurementData.map((device) => device.data[0].friendlyName).join('-')}-${measurement}`,
              },
            }
          },
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: 'datetime',
          min: zoomRange.min,
          max: zoomRange.max,
          labels: {
            datetimeUTC: false 
          }
        },
        yaxis: {
          stepSize: stepSizeBasedOnMeasurement.get(measurement) ?? undefined,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: '12px',
            },
            minWidth: 50,
          },
        },
        legend: {
          formatter(_legendName, opts) {
            return `${devicesWithSelectedMeasurementData[opts.seriesIndex].data[0].friendlyName}`;
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: () => {
                return '';
              }
            },
            formatter: (val, opts) => {
              const chartData = opts.w.config.series[opts.seriesIndex].data;
              const index = opts.dataPointIndex;
              const date = new Date(chartData[index].x);
              const friendlyName = data[opts.seriesIndex].data[index].friendlyName;

              return `${friendlyName}: ${val} - ${date.toLocaleString(undefined, {timeZoneName: "shortOffset"})}`
            }
          }
        },
        colors: devicesWithSelectedMeasurementData.map((device) => device.data[0].color),
      }}
      series={devicesWithSelectedMeasurementData.map((device) => ({
        name: device.data[0].friendlyName,
        data: device.data.map(({ x, y }) => ({
          x,
          y,
        })),
      }))}
      type="line"
    />
  );
};

export default CustomLineChart; 
