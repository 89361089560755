import { SimpleTreeView } from "@mui/x-tree-view";
import { DndProvider } from "react-dnd"
import { useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";

import useFetch from "hooks/useFetch";
import { getAuthConfig } from "utils";
import { deviceManagerAPI } from "api";
import HierarchyItem from "./HierarchyItem";
import { Asset, DeviceState, Hierarchy, HierarchyNode, MinimalNode, Tag } from "types/custom";
import { usePageLayoutContext } from "components/common/layout";
import { reAttachNode, deleteNode, toMinimalNode, findSourceNodeAndParentInTree, makeNodeFromSensor } from "./utils";
import { API_URL_PATH_HIERARCHY, API_URL_PATH_TAG, API_URL_PATH_DM_CLAIMED, API_URL_PATH_TAG_BY_HIERARCHY } from "constants/urls";
import { useDashboardContext } from "providers/DashboardProvider";
import useFetchWithReactQuery from "hooks/useFetchWithReactQuery";
import { Modal } from "@cloudscape-design/components";
import DeviceTabs from "components/device-manager/DeviceTabs";

const HierarchyTree = () => {
  const [hierarchyTree, setHierarchyTree] = useState<HierarchyNode>();
  const [enabledDevices, setEnabledDevices] = useState<Asset[]>([]);
  const [fetchedTagNodes, setFetchedTagNodes] = useState<HierarchyNode[]>([]);
  const [allIds, setAllIds] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [expandWith, setExpandWith] = useState<string | null>(null);
  const [devicesPresentInTree, setDevicesPresentInTree] = useState<string[]>([]);
  const [deviceDetailsModal, setDeviceDetailsModal] = useState<string | null>(null);

  const { setNotification } = usePageLayoutContext();

  const { setSelectedNode, setChartDevices, chartDevices, setRolesSelectDisabled, selectedRole, expandAll, setExpandAll } = useDashboardContext();

  const { response: hierarchy, loading: isHierarchyLoading, error: hierarchyError, fetchData: hierarchyFetch } = useFetch<Hierarchy>(
    {
      axiosInstance: deviceManagerAPI,
      method: 'GET',
      url: `${API_URL_PATH_HIERARCHY}/${selectedRole || 'forUser'}`,
    },
    { manual: true }
  );

  useEffect(() => {
    hierarchyFetch();
    setHierarchyTree(undefined);
    setEnabledDevices([]);
    setAllIds([]);
    setDevicesPresentInTree([]);
  }, [selectedRole]);

  const { fetchData: fetchTagById, loading: isTagLoading } = useFetch<HierarchyNode>(
    {
      axiosInstance: deviceManagerAPI,
      method: 'GET',
    },
    { manual: true }
  );

  const { fetchData: allTagsForHierarchy, loading: isAllTagsLoading } = useFetch<{ data: { items: Tag[] } }>({
    axiosInstance: deviceManagerAPI,
    method: 'GET',
  }, { manual: true });

  const {
    data: devicesResponse,
    error: devicesError,
    isLoading: isDevicesLoading,
  } = useFetchWithReactQuery<{ items: Asset[] }>({
    axiosInstance: deviceManagerAPI,
    url: API_URL_PATH_DM_CLAIMED,
    key: 'devices',
  });

  useEffect(() => {
    setRolesSelectDisabled(isHierarchyLoading || isTagLoading || isAllTagsLoading || isDevicesLoading);
  }, [isHierarchyLoading, isTagLoading, isAllTagsLoading, isDevicesLoading]);

  const fetchTags = async (node: HierarchyNode) => {
    const fetchedTagNode = fetchedTagNodes.find((currentNode: HierarchyNode) => currentNode.id === node.id);
    if (fetchedTagNode) return fetchedTagNode;

    if (node.isDevice) return node; // Current node is not a tag, do not fetch

    if (!allIds.includes(node.id)) setAllIds([...allIds, node.id]);
    const tagDataResponse: { data: Tag } | 'canceled' = await fetchTagById(`${API_URL_PATH_TAG}/${node.id}`);
    if (tagDataResponse === 'canceled') return;
    if (!tagDataResponse || !tagDataResponse.data) {
      setNotification([
        {
          type: 'error',
          content: 'Error fetching tag data',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'fetch-tag-data-error',
        },
      ]);
      return node
    }
    node.name = tagDataResponse.data.name;
    node.assetList = tagDataResponse.data.assetList;
    setFetchedTagNodes((prev) => [...prev, node]);
    return node;
  };

  useEffect(() => {
    (async () => {
      if (!isHierarchyLoading && hierarchy) {

        const response = await allTagsForHierarchy(`${API_URL_PATH_TAG_BY_HIERARCHY}/${hierarchy.id}`);
        if (response === 'canceled') return;
        if (!response || !response.data) {
          setNotification([
            {
              type: 'error',
              content: 'Error fetching all tags for one level hierarchy',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setNotification([]),
              id: 'fetch-all-tag-data-error',
            },
          ]);
          return
        }
        const tagIds: string[] = [];
        const nodes = hierarchy.hierarchyTreeData.map(minimalNode => toHierarchyNode(response.data.items, minimalNode, tagIds)).filter(x => x !== undefined) as HierarchyNode[];
        setAllIds(tagIds);
        setFetchedTagNodes(nodes);
        setHierarchyTree(nodes[0]);
      }
    })();
  }, [isHierarchyLoading, expandAll]);

  useEffect(() => {
    if (expandAll) {
      setExpanded(allIds);
      setExpandAll(false);
    }
  }, [expandAll]);

  const toHierarchyNode = (tags: Tag[], minimalNode: MinimalNode, tagIds?: string[]): HierarchyNode => {
    const tag = tags.find(tag => tag.id === minimalNode.tagId);

    setDevicesPresentInTree((prev: string[]) => [...prev, ...tag!.assetList]);
    if (Array.isArray(tagIds) && !tagIds.includes(minimalNode.tagId) && minimalNode.children?.length) tagIds.push(minimalNode.tagId);

    return {
      id: minimalNode.tagId,
      name: tag?.name || undefined,
      assetList: tag?.assetList || [],
      children: (minimalNode.children || []).map(child => toHierarchyNode(tags, child, tagIds)),
      isDevice: false,
    } as HierarchyNode;
  };

  const handleItemClick = async (node: HierarchyNode) => {
    setSelectedNode(node);
    if (!node.isRendered) {
      node.isRendered = true;
      const devices = devicesResponse.items.filter((x) => node?.assetList?.includes(x.name)).map(makeNodeFromSensor) || [];
      const tags = await Promise.all(node.children?.map((child) => fetchTags(child) || [])) as HierarchyNode[];
      node.children = [...tags, ...devices].filter(x => x !== undefined);
      if (!expanded.includes(node.id) && node.children.length) setExpandWith(node.id);
    }
  };

  useEffect(() => {
    // This is used to ensure expanded will always have the latest available value
    if (expandWith) {
      setExpanded([...expanded, expandWith]);
      setExpandWith(null);
    }
  }, [expandWith]);

  const handleNodeReorder = async (source: HierarchyNode, destination: HierarchyNode) => {
    if (!hierarchyTree) return;

    if (source.isDevice && destination?.isDevice) {
      setNotification([
        {
          type: 'error',
          content: 'You can only reorder tags.',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'reorder-devices-error',
        },
      ]);
      return;
    }

    if (source.isDevice && !destination?.isDevice) {
      const { parent: parentContainingDevice } = findSourceNodeAndParentInTree(source, hierarchyTree);
      if (!parentContainingDevice || destination.id === parentContainingDevice.id) {
        return null;
      }

      deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${parentContainingDevice.id}`, {
        assetList: parentContainingDevice?.assetList?.filter((asset: string) => asset !== source.name),
        runDuplicateAssetValidation: false,
      }, await getAuthConfig());

      deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${destination.id}`, {
        assetList: [...(destination?.assetList || []), source.name],
        runDuplicateAssetValidation: false,
      }, await getAuthConfig());
    }

    try {
      const updated = reAttachNode(source, destination, hierarchyTree);
      if (updated) {
        await handleSaveHierarchy();
        setHierarchyTree({ ...updated });
      }
    } catch (error: any) {
      setNotification([
        {
          type: 'error',
          content: error.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'reorder-node-error',
        },
      ]);
    }
  };

  const handleNodeDelete = async (node: HierarchyNode) => {
    if (!hierarchyTree) return;

    if (!window.confirm('Are you sure you want to delete this node?')) return;

    if (node?.assetList?.length) {
      setDevicesPresentInTree((prev: string[]) => prev.filter((asset: string) => !node?.assetList?.includes(asset)));
    }

    deleteNode(node, hierarchyTree);
    await handleSaveHierarchy();
    setHierarchyTree({ ...hierarchyTree });
  };

  const handleNodeAdded = async (parent: HierarchyNode, child: HierarchyNode) => {
    if (!hierarchyTree) return;
    await handleSaveHierarchy();
    setAllIds([...allIds, parent.id!]);
    setHierarchyTree({ ...hierarchyTree });
  };

  const handleSaveHierarchy = async () => {
    if (!hierarchy || !hierarchyTree) {
      setNotification([
        {
          type: 'error',
          content: 'Hierarchy is not loaded',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'hierarchy-not-loaded-error',
        },
      ]);
      return;
    }
    const authConfig = await getAuthConfig();
    await deviceManagerAPI.patch(`${API_URL_PATH_HIERARCHY}/${hierarchy?.id}`, {
      hierarchyTreeData: [toMinimalNode(hierarchyTree)],
    }, authConfig);
  };

  const handleDeviceDetach = async (deviceNode: HierarchyNode) => {
    if (!hierarchyTree) return;

    if (!window.confirm('Are you sure you want to detach this device?')) return;

    const { parent: tagContainingDevice } = findSourceNodeAndParentInTree(deviceNode, hierarchyTree);
    const tagDataResponse = await fetchTagById(`${API_URL_PATH_TAG}/${tagContainingDevice?.id}`);
    if (tagDataResponse === 'canceled') return;
    if (!tagDataResponse || !tagDataResponse.data) {
      setNotification([
        {
          type: 'error',
          content: 'Error fetching tag data after detach',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'fetch-tag-by-id-data-error',
        },
      ]);
      return
    }
    const tagData = tagDataResponse.data;

    tagData.assetList = tagData.assetList.filter((asset: string) => asset !== deviceNode.name);
    setDevicesPresentInTree((prev: string[]) => prev.filter((asset: string) => asset !== deviceNode.name));

    const authConfig = await getAuthConfig();
    await deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${tagContainingDevice?.id}`, {
      assetList: tagData.assetList,
      runDuplicateAssetValidation: false,
    }, authConfig);

    await handleSaveHierarchy();
    deleteNode(deviceNode, hierarchyTree);
    const newChartDevices = chartDevices.filter((x) => x.name !== deviceNode.name);
    setChartDevices(newChartDevices);
    setHierarchyTree({ ...hierarchyTree });
  }

  useEffect(() => {
    if (devicesResponse?.items?.length && !devicesError) {
      setEnabledDevices(devicesResponse.items.filter((x: any) => x.deviceState === DeviceState.inService).map((device: any) => ({
        label: device.friendlyName || device.name,
        labelTag: device.name,
        value: device.name,
        disabled: devicesPresentInTree.includes(device.name),
        ...device,
      })));
    }

  }, [hierarchy, isHierarchyLoading, expandAll, hierarchyTree, devicesPresentInTree, devicesResponse, devicesError]);

  if (hierarchyError && !isHierarchyLoading) {
    return (
      <Alert severity="error">
        <AlertTitle>No data available</AlertTitle>
        {hierarchyError}
      </Alert>
    );
  }

  if (isHierarchyLoading || !hierarchyTree) return <CircularProgress size={35} style={{ color: 'black' }} />;

  return (<>
    <Modal
      onDismiss={() => setDeviceDetailsModal(null)}
      visible={deviceDetailsModal !== null}
      size="large"
      header={deviceDetailsModal}>
      <DeviceTabs selectedDevices={devicesResponse.items.find(device => device.name === deviceDetailsModal)} />
    </Modal>
    <DndProvider backend={HTML5Backend}>
      <SimpleTreeView
        onExpandedItemsChange={(_event, nodeIds) => setExpanded(nodeIds)}
        expandedItems={expanded}
        itemChildrenIndentation={8}
      >
        <HierarchyItem
          node={hierarchyTree!}
          canHaveChildren
          isRootTag={true}
          hierarchyId={hierarchy!.id}
          enabledDeviceOptions={enabledDevices ?? []}
          onItemDropped={handleNodeReorder}
          onItemClick={handleItemClick}
          onNodeDelete={handleNodeDelete}
          onDeviceDetach={handleDeviceDetach}
          onNodeAdded={handleNodeAdded}
          devicesPresentInTree={devicesPresentInTree}
          setDevicesPresentInTree={setDevicesPresentInTree}
          setDeviceDetailsModal={setDeviceDetailsModal}
        />
      </SimpleTreeView>
    </DndProvider>
  </>);
};

export default HierarchyTree;