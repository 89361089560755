import { Auth } from "aws-amplify";
import { StateAlertConfigCode } from "types/custom";

export const selectFewerProps = (data: any) => {
    const { name: label, id: { id: value } = { id: {} } } = data;
    return { label, value };
};

export const selectLocationProps = (data: any, type: any) => {
    const code = data[type + 'no'] || data[type + 'code']
    return {
        label: data[type] || data[type + 'name'] || data[type + 'id'],
        value: data[type + 'id'],
        labelTag: code ? `${code} [${data[type + 'id']}]` : data[type + 'id'],
        description: `Created at ${new Date(data.datecreated).toLocaleString().replace(',', '')}${data.datemodified ? '. Modified at ' + new Date(data.datemodified).toLocaleString().replace(',', '') : ''}`,
    };
};

export const getMatchesCountText = (count: number | undefined) => {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
};

export const convertUTCHourToLocal = (value: string) => {
    const getMillisecondsForHour = new Date().setUTCHours(+value);
    return new Date(getMillisecondsForHour).getHours();
};

export const convertLocalHourToUTC = (value: string) => {
    const getMillisecondsForHour = new Date().setHours(+value);
    return new Date(getMillisecondsForHour).getUTCHours();
};

export const getAuthConfig = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };
    return config;
};

export function toTitleCase(str: string) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
};

export function thresholdCodeToName(code?: StateAlertConfigCode) {
    switch (code) {
        case StateAlertConfigCode.LL:
            return 'Low-Low';
        case StateAlertConfigCode.L:
            return 'Low';
        case StateAlertConfigCode.H:
            return 'High';
        case StateAlertConfigCode.HH:
            return 'High-High';
        default:
            return ''
    }
}