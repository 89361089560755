import { Select, SelectProps } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useDashboardContext } from 'providers/DashboardProvider';
import { userManagerAPI } from 'api';
import { API_URL_PATH_UM_ROLES } from 'constants/urls';
import { UserRolesProps } from 'types/custom';
import useAuth from 'hooks/useAuth';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';

const RolePicker = () => {
	const { isUsingLocationsHierarchy, rolesSelectDisabled, setSelectedRole } = useDashboardContext();
	const [role, setRole] = useState<SelectProps.Option>({ label: "Assigned Role", value: undefined, description: "Default hierarchy" });
	const [allRoles, setAllRoles] = useState<SelectProps.Options>([]);
	const showRolePicker = useAuth().privileges === 'super_admin';

	const { data: rolesResponse, error: rolesError, isLoading: rolesLoading, status: rolesStatus } = useFetchWithReactQuery({
		axiosInstance: userManagerAPI,
		url: API_URL_PATH_UM_ROLES,
		key: 'roles',
		enabled: showRolePicker
	});

	useEffect(() => {
		if (rolesStatus === "success") {
			setAllRoles([
				{ label: "Assigned Role", value: undefined, description: "Default hierarchy" },
				...rolesResponse?.items.map((role: UserRolesProps) => ({
				value: role.name,
				label: role.name,
				description: role.description,
				}))
			] || []);
		}
	}, [rolesStatus, rolesResponse]);

	if (isUsingLocationsHierarchy || !showRolePicker) return <></>;

	return (
		<Select
		selectedOption={role}
		statusType={rolesError ? 'error' : rolesLoading ? 'loading' : undefined}
		disabled={rolesSelectDisabled}
		loadingText='Loading roles'
		placeholder='Choose a role'
		filteringType='auto'
		onChange={(e: any) => {
			setRole(e.detail.selectedOption)
			setSelectedRole(e.detail.selectedOption.value)
		}}
		options={allRoles}
		/>
	)
}

export default RolePicker;