import { useState } from 'react';
import {
  Grid,
  ColumnLayout,
  Container,
  Header,
  Button,
  SpaceBetween,
} from '@cloudscape-design/components';

import ContactsList from './ContactsList';
import CreateUpdateContact from './CreateUpdateContact';
import { useIncidentManagerContext } from 'pages/incident-manager/IncidentManagerPage';

const Contacts = () => {
  const [isUpdatingContact, setIsUpdatingContact] = useState(true);

  const { contactsFetching, contactsRefetch} = useIncidentManagerContext();

  return (
    <Grid
      gridDefinition={[
        { colspan: { l: 6, m: 6, s: 6, xs: 12, xxs: 12 } },
        { colspan: { l: 6, m: 6, s: 6, xs: 12, xxs: 12 } },
      ]}
    >
      <ColumnLayout variant='text-grid'>
        <Container
            header={
              <Header variant='h3' actions={<SpaceBetween size='xs' direction='horizontal'>
                <Button onClick={() => setIsUpdatingContact(false)} variant='normal' disabled={contactsFetching}>Create</Button>
                <Button iconName='refresh' onClick={contactsRefetch} loading={contactsFetching}/>
                </SpaceBetween>}>
                Contacts List
              </Header>
              }
            fitHeight={true}
        >
            <ContactsList setIsUpdatingContact={setIsUpdatingContact} />
        </Container>
          

      </ColumnLayout>
      <ColumnLayout variant='text-grid'>
        <Container>
          <CreateUpdateContact isUpdating={isUpdatingContact} setIsUpdatingContact={setIsUpdatingContact} />
        </Container>
      </ColumnLayout>
    </Grid>
  );
};

export default Contacts;
