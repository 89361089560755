import { useLocation, Navigate, Outlet } from 'react-router-dom';

import Spinner from 'components/spinner/Spinner';
import useAuth from 'hooks/useAuth';

const RequireAuth = ({ allowedRoles }: { allowedRoles: string[] }) => {
    const { token, loading, roles } = useAuth();
    const location = useLocation();

    if (loading && !token) {
        return <Spinner />;
    }

    if (
        !loading &&
        token &&
        (roles?.find((role) => allowedRoles?.includes(role)) ||
            !allowedRoles.length)
    ) {
        return <Outlet />;
    }

    if (!loading && token) {
        return <Navigate to='/unauthorized' />;
    }

    return <Navigate to='/login' state={{ from: location }} replace />;
};

export default RequireAuth;
