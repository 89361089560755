import { StatusIndicator, StatusIndicatorProps } from "@cloudscape-design/components";
import { IncidentStatus } from "types/custom";
import { toTitleCase } from "utils";

const IncidentStatusBadge = ({status}: {status: IncidentStatus}) => {
	let indType: StatusIndicatorProps.Type = 'info';
	switch (status) {
		case IncidentStatus.OPEN:
			indType = 'error';
			break;
		case IncidentStatus.CLEARED:
			indType = 'info';
			break;
		case IncidentStatus.RESOLVED:
			indType = 'success';
			break;
		case IncidentStatus.ACKNOWLEDGED:
			indType = 'warning';
			break;
		case IncidentStatus.FORWARDED:
			indType = 'info';
			break;
	}
	return (<StatusIndicator type={indType}>{toTitleCase(status)}</StatusIndicator>);
}

export default IncidentStatusBadge;