import { ColumnLayout, Box } from '@cloudscape-design/components';
import { userManagerAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeviceGroupTable from 'components/device-group-manager/DeviceGroupTable';
import DeviceGroupTabs from 'components/device-group-manager/DeviceGroupTabs';
import Spinner from 'components/spinner/Spinner';
import { API_URL_PATH_GROUP_LIST } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeviceGroupProps } from 'types/custom';

const DeviceGroupsContext = createContext({
    allGroups: [] as DeviceGroupProps[],
    loading: false,
    error: '',
    selectedGroup: [] as DeviceGroupProps[],
    setSelectedGroup: (state: DeviceGroupProps[]) => { },
    refetch: () => { },
});

export const useDeviceGroupsContext = () => useContext(DeviceGroupsContext);

const DeviceGroupListPage = () => {
    const [allGroups, setAllGroups] = useState<DeviceGroupProps[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<DeviceGroupProps[]>([]);
    const { setNotification } = usePageLayoutContext();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const locationState = location?.state;
        if (locationState?.action) {
            setNotification([
                {
                    type: 'success',
                    content: locationState.message,
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setNotification([]),
                    id: `flash-${locationState.action}`,
                },
            ]);
        }

        navigate(location.pathname, {});
    }, []);

    const { response, error, loading, status, refetch } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'GET',
            url: API_URL_PATH_GROUP_LIST,
        },
        { manual: false }
    );

    useEffect(() => {
        if (status === 200) {
            setAllGroups(response?.items || []);
        }
    }, [status, response, refetch]);

    return (
        <DeviceGroupsContext.Provider
            value={{
                loading,
                allGroups,
                error,
                selectedGroup,
                setSelectedGroup,
                refetch,
            }}
        >
            <ColumnLayout variant='text-grid'>
                {loading && (
                    <>
                        <br></br>
                        <Spinner />
                    </>
                )}
                {allGroups && <DeviceGroupTable />}
            </ColumnLayout>

            {allGroups && selectedGroup![0]?.groupId !== undefined && (
                <>
                    <Box margin={{ top: 'xxxl' }}></Box>
                    <ColumnLayout variant='text-grid'>
                        <DeviceGroupTabs />
                    </ColumnLayout>
                </>
            )}
        </DeviceGroupsContext.Provider>
    );
};

export default DeviceGroupListPage;
