import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FormField, Button, Input, TimeInput, Header, TextContent, Icon } from '@cloudscape-design/components';

import useFetch from 'hooks/useFetch';
import { incidentManagerAPI } from 'api';
import { API_URL_PATH_IM_CONTACTS } from 'constants/urls';
import { convertLocalHourToUTC, convertUTCHourToLocal } from 'utils';
import { useIncidentManagerContext } from 'pages/incident-manager/IncidentManagerPage';

type CreateContactValues = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  startHour: string;
  endHour: string;
  alias: string;
};

type CreateUpdateContactProps = {
  isUpdating: boolean;
  setIsUpdatingContact: (state: boolean) => void;
}

enum CONTACT_CHANNEL {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
};

const CreateUpdateContact = ({ isUpdating, setIsUpdatingContact }: CreateUpdateContactProps) => {
  const { contactsRefetch, selectedContact } = useIncidentManagerContext();
  const [isEmailActive, setIsEmailActive] = useState(false);
  const [isMobileActive, setIsMobileActive] = useState(false);

  const createContact = async (values: CreateContactValues) => {
    if (isUpdating) {
      await createUpdateContact(`${API_URL_PATH_IM_CONTACTS}/${selectedContact?.alias}`, {
        phone: values.mobile,
        onCall: {
          start: convertLocalHourToUTC(values.startHour),
          end: convertLocalHourToUTC(values.endHour),
        },
      });
    } else {
      await createUpdateContact(API_URL_PATH_IM_CONTACTS, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.mobile,
        onCall: {
          start: convertLocalHourToUTC(values.startHour),
          end: convertLocalHourToUTC(values.endHour),
        },
        alias: values.alias,
        active: true,
      });
      setIsUpdatingContact(true);
    }
    contactsRefetch();
  }

  const { handleSubmit, setValues, values, errors, touched, setTouched } = useFormik<CreateContactValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      startHour: '',
      endHour: '',
      alias: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email'),
      mobile: Yup.string().matches(/^\+?\d+$/, "Phone number must start with a '+' and contain only numbers"),
      startHour: Yup.string().required('Start Hour is required'),
      endHour: Yup.string().required('End Hour is required'),
      alias: Yup.string().required('Alias is required'),
    }),
    onSubmit: createContact,
  });

  const { fetchData: createUpdateContact, loading } = useFetch(
    {
      axiosInstance: incidentManagerAPI,
      method: isUpdating ? 'PATCH' : 'POST',
    },
    { manual: true }
  );

  useEffect(() => {
    if (selectedContact) {
      selectedContact.contactChannels?.forEach((channel: any) => {
        if (channel.type === CONTACT_CHANNEL.EMAIL) {
          setIsEmailActive(channel.active);
        }
        if (channel.type === CONTACT_CHANNEL.SMS) {
          setIsMobileActive(channel.active);
        }
      });

      setValues({
        firstName: String(selectedContact.firstName),
        lastName: String(selectedContact.lastName),
        email: String(selectedContact.email),
        mobile: String(selectedContact.phone),
        startHour: String(convertUTCHourToLocal(selectedContact.onCall.start)),
        endHour: String(convertUTCHourToLocal(selectedContact.onCall.end)),
        alias: String(selectedContact.alias),
      });
    }
  }, [selectedContact, setValues]);

  useEffect(() => {
    if (!isUpdating) {
      setValues({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        startHour: '',
        endHour: '',
        alias: '',
      });
    }
  }, [isUpdating, setValues]);

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: "550px" }}>
      <div style={{ paddingBottom: "20px" }}>
        <Header variant='h3'>
          {isUpdating ? "Update" : "Create"} Contact
        </Header>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label="Alias"
          errorText={touched.alias && errors.alias ? errors.alias : ""}>
          <Input
            name="alias"
            disabled={isUpdating || loading}
            value={values.alias}
            onChange={({ detail }) => setValues({ ...values, alias: detail.value })}
            onBlur={(e) => setTouched({ ...touched, alias: true })}
            onFocus={(e) => setTouched({ ...touched, alias: false })}
            placeholder="Alias" />
        </FormField>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label="First Name"
          errorText={touched.firstName && errors.firstName ? errors.firstName : ""}>
          <Input
            name="firstName"
            disabled={isUpdating || loading}
            value={values.firstName}
            onChange={({ detail }) => setValues({ ...values, firstName: detail.value })}
            onBlur={(e) => setTouched({ ...touched, firstName: true })}
            onFocus={(e) => setTouched({ ...touched, firstName: false })}
            placeholder="First Name" />
        </FormField>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label="Last Name"
          errorText={touched.lastName && errors.lastName ? errors.lastName : ""}>
          <Input
            name="lastName"
            disabled={isUpdating || loading}
            value={values.lastName}
            onChange={({ detail }) => setValues({ ...values, lastName: detail.value })}
            onBlur={(e) => setTouched({ ...touched, lastName: true })}
            onFocus={(e) => setTouched({ ...touched, lastName: false })}
            placeholder="Last Name" />
        </FormField>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {isUpdating &&
                <Icon
                  name={isEmailActive ? 'status-positive' : 'status-warning'}
                  size='medium'
                  variant={isEmailActive ? 'success' : 'subtle'}
                />
              }
              <span style={{ marginLeft: "5px" }}>Email</span>
            </div>
          }
          errorText={touched.email && errors.email ? errors.email : ""}>
          <Input
            name="email"
            disabled={isUpdating || loading}
            value={values.email}
            onChange={({ detail }) => setValues({ ...values, email: detail.value })}
            onBlur={(e) => setTouched({ ...touched, email: true })}
            onFocus={(e) => setTouched({ ...touched, email: false })}
            placeholder="Email" />
        </FormField>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {isUpdating &&
                <Icon
                  name={isMobileActive ? 'status-positive' : 'status-warning'}
                  size='medium'
                  variant={isMobileActive ? 'success' : 'subtle'}
                />
              }
              <span style={{ marginLeft: "5px" }}>Mobile</span>
            </div>
          }
          errorText={touched.mobile && errors.mobile ? errors.mobile : ""}>
          <Input
            name="mobile"

            value={values.mobile}
            disabled={loading}
            onChange={({ detail }) => setValues({ ...values, mobile: detail.value })}
            onBlur={(e) => setTouched({ ...touched, mobile: true })}
            onFocus={(e) => setTouched({ ...touched, mobile: false })}
            placeholder="Mobile" />

        </FormField>
      </div>

      <div style={{ display: "flex", justifyContent: 'space-between', paddingBottom: "10px" }}>
        <FormField
          label="Start Hour"
          errorText={touched.startHour && errors.startHour ? errors.startHour : ""}>
          <TimeInput
            value={values.startHour}
            disabled={loading}
            onChange={({ detail }) => setValues({ ...values, startHour: detail.value })}
            onBlur={(e) => setTouched({ ...touched, startHour: true })}
            onFocus={(e) => setTouched({ ...touched, startHour: false })}
            format='hh'
            placeholder='hh'
          />
        </FormField>
        <FormField
          label="End Hour"
          errorText={touched.endHour && errors.endHour ? errors.endHour : ""}>
          <TimeInput
            value={values.endHour}
            disabled={loading}
            onChange={({ detail }) => setValues({ ...values, endHour: detail.value })}
            onBlur={(e) => setTouched({ ...touched, endHour: true })}
            onFocus={(e) => setTouched({ ...touched, endHour: false })}
            format='hh'
            placeholder='hh'
          />
        </FormField>
      </div>

      <div style={{ paddingBottom: "20px" }}>
        <TextContent>
          Time Zone: <strong>{Intl.DateTimeFormat().resolvedOptions().timeZone}</strong>
        </TextContent>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button formAction="submit" variant="primary" loading={loading}>
          {isUpdating ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  )
};

export default CreateUpdateContact;