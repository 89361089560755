import { AppLayout, Flashbar, FlashbarProps } from '@cloudscape-design/components';
import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import SideNav from 'components/common/sidenav';
import TopNav from 'components/common/topnav';

const PageLayoutContext = createContext({
    setNotification: (state: FlashbarProps.MessageDefinition[]) => { },
});

export const usePageLayoutContext = () => useContext(PageLayoutContext);

const Layout = () => {
    const [notification, internalSetNotification] = useState<FlashbarProps.MessageDefinition[]>([]);

    return (
        <PageLayoutContext.Provider value={{ setNotification: (n: FlashbarProps.MessageDefinition[]) => {
            // Group existing notifications with new ones. Then set to distinct ids to ensure flash bar works well.
            internalSetNotification(notifications => [...notifications, ...n].map((n, i) => ({ ...n, id: `n-${i}` })));
        }}}>
            <TopNav />
            <AppLayout
                toolsHide
                headerVariant="high-contrast"
                stickyNotifications={true}
                navigation={<SideNav />}
                content={<Outlet />}
                notifications={<Flashbar items={notification.map((n) => ({
                    ...n,
                    // Always override the following props of the notifications
                    dismissible: true,
                    onDismiss: () => internalSetNotification(notifications => notifications.filter(item => item.id !== n.id)),
                })).reverse()} stackItems={true} />}
                disableContentPaddings
            ></AppLayout>
        </PageLayoutContext.Provider>
    );
};

export default Layout;
