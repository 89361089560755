import { Alert, Button, KeyValuePairs, Modal, SpaceBetween, Toggle } from '@cloudscape-design/components';
import { deviceManagerAPI } from 'api';
import { API_URL_PATH_DM_RULE } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import { useState } from 'react';
import { AlertRule } from 'types/custom';
import { thresholdCodeToName, toTitleCase } from 'utils';
import AlertStatusIndicator from '../AlertStatusIndicator';
import { relativeToText, secondsToRelative } from '../CreateAlertModal/utils';

const AlertDetailsModal = ({
    visible,
    setVisible,
    alertRule: initialAlertRule,
}: {
    visible: boolean;
    setVisible: (state: boolean) => void;
    alertRule: AlertRule;
}) => {
    const [alertRule, setAlertRule] = useState<AlertRule>(initialAlertRule);

    const {
        fetchData: previousAlertRule,
        error: previousAlertRuleError,
        loading: previousAlertRuleLoading,
    } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'GET',
            url: `${API_URL_PATH_DM_RULE}/${alertRule.previous_version_id}`,
        },
        { manual: true }
    );

    const goToPreviousVersion = async () => {
        const response = await previousAlertRule();
        if (response?.status === 200) {
            setAlertRule(response.data);
        }
    }

    const notificationFrequencyToString = (notificationFrequency: Record<string, number>) => {
        const items = [];
        for (const key in notificationFrequency) {
            items.push(`${toTitleCase(key)}: ${relativeToText(secondsToRelative(notificationFrequency[key], true), 'Never renotify', 'Always notify')}`);
        }
        return items.join('. ');
    };

    return (
        <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        closeAriaLabel='Close modal'
        header='Alert Rule Details'
        size='medium'
        footer={`Rule ID: ${alertRule.id}`}
        >
            <SpaceBetween size='l'>
                <KeyValuePairs
                columns={2}
                items={[
                    { label: 'Status', value: (<AlertStatusIndicator status={alertRule.status}/>) },
                    { label: 'Device ID', value: alertRule.deviceId },
                    { label: 'Measure', value: snakeCaseToNormalCase(alertRule.measure) },
                    { label: 'Threshold Code', value: thresholdCodeToName(alertRule.threshold_code) },
                    { label: 'Threshold Value', value: alertRule.threshold_value },
                    { label: 'Deadband Period', value: relativeToText(secondsToRelative(alertRule.deadband_period), '') || (<i>Disabled</i>) },
                    { label: 'Escalation Plan', value: alertRule.escalation_plan || (<i>None</i>) },
                    { label: 'Notification Frequency', value: notificationFrequencyToString(alertRule.notification_frequency) },
                    { label: 'Created By', value: alertRule.createdBy || '-' },
                    { label: 'Updated By', value: alertRule.updatedBy || '-' },
                    { label: 'Created At', value: new Date(alertRule.createdAt * 1000).toLocaleString(undefined, {timeZoneName: "shortOffset"}) },
                    { label: 'Updated At', value: new Date(alertRule.updatedAt * 1000).toLocaleString(undefined, {timeZoneName: "shortOffset"}) },
                    { label: 'Forward to Monitoring Bus', value: <Toggle checked={alertRule.forward} readOnly={true}>{alertRule.forward ? 'Enabled' : 'Disabled'}</Toggle>},
                    { label: 'Notes', value: alertRule.notes || '-' },
                ]}
                />
                <SpaceBetween direction='horizontal' size='s'>
                    <Button
                    iconName='arrow-left'
                    loading={previousAlertRuleLoading}
                    disabled={!alertRule.previous_version_id}
                    disabledReason='No previous version'
                    onClick={goToPreviousVersion}>
                        Previous Version
                    </Button>
                    <Button
                    iconName='arrow-right'
                    iconAlign='right'
                    disabled={initialAlertRule.id === alertRule.id}
                    disabledReason='No newer version'
                    onClick={()=> setAlertRule(initialAlertRule)}>
                        Latest Version
                    </Button>
                </SpaceBetween>
                {previousAlertRuleError && <Alert type='error'>{previousAlertRuleError}</Alert>}
            </SpaceBetween>
        </Modal>
    );
};

export default AlertDetailsModal;