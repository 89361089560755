import {
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    SpaceBetween,
} from '@cloudscape-design/components';
import { buttonDropdownItems } from 'components/device-manager/DeviceTable/table-config';
import { EmptyState } from 'components/empty-state/EmptyState';
import { UserRolesProps } from 'types/custom';

export const EmptyStateContent = ({ name = 'instances' } : { name?: string }) => {
    return (
        <EmptyState
            title={`No ${name}`}
            subtitle={`No ${name} found.`}
            action={undefined}
        />
    );
};

export const NoMatchContent = ({ actions }: any) => {
    return (
        <EmptyState
            title='No matches'
            subtitle='No matches found.'
            action={
                <Button onClick={() => actions.setFiltering('')}>
                    Clear filter
                </Button>
            }
        />
    );
};

export const PaginationContent = ({ paginationProps }: any) => {
    return (
        <Pagination
            {...paginationProps}
            ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
        />
    );
};

export const HeaderContent = ({
    allItems,
    selectedItem,
    handleButtonDropdownClick,
}: {
    allItems: any[];
    selectedItem: UserRolesProps[] | undefined;
    handleButtonDropdownClick: () => void;
}) => {
    return (
        <Header
            counter={
                selectedItem?.length && selectedItem[0]?.name
                    ? '(' +
                      selectedItem.length +
                      `/${allItems && allItems.length})`
                    : `(${allItems && allItems.length})`
            }
            actions={
                <SpaceBetween direction='horizontal' size='xs'>
                    <ButtonDropdown
                        items={buttonDropdownItems(allItems)}
                        onItemClick={handleButtonDropdownClick}
                    >
                        Actions
                    </ButtonDropdown>
                </SpaceBetween>
            }
        >
            List of roles
        </Header>
    );
};
