export const relativeTimeMap = new Map<string, number>([
  ["previous-5-minutes", 5 * 60 * 1000],
  ["previous-30-minutes", 30 * 60 * 1000],
  ["previous-1-hour", 60 * 60 * 1000],
  ["previous-3-hours", 3 * 60 * 60 * 1000],
  ["previous-6-hours", 6 * 60 * 60 * 1000],
  ["previous-12-hours", 12 * 60 * 60 * 1000],
  ["previous-24-hours", 24 * 60 * 60 * 1000],
])

export const timeUnitMap = new Map<string, number>([
  ["minute", 60 * 1000],
  ["hour", 60 * 60 * 1000],
  ["day", 24 * 60 * 60 * 1000],
  ["week", 7 * 24 * 60 * 60 * 1000],
  ["month", 30 * 24 * 60 * 60 * 1000],
  ["year", 365 * 24 * 60 * 60 * 1000],
])
