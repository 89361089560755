import {
    Box,
    Button,
    FormField,
    Modal,
    Multiselect,
    SpaceBetween,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { deviceManagerAPI, userManagerAPI } from 'api';
import {
    API_URL_PATH_DM_CLAIMED,
    API_URL_PATH_GROUP_LIST,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import { Asset, DeviceGroupProps } from 'types/custom';

interface AssetProps {
    assetCategory: string;
    assetId: string;
}

const AddRemoveAssetModal = ({
    selectedGroup,
    visible,
    onDiscard,
    action,
    refetch,
}: {
    selectedGroup: DeviceGroupProps[];
    visible: boolean;
    onDiscard: () => void;
    action: string;
    refetch: () => void;
}) => {
    const [allAssets, setAllAssets] = useState<OptionDefinition[]>();
    const [selectedAssets, setSelectedAssets] = useState<
        readonly OptionDefinition[]
    >([]);
    const [assetPayload, setAssetPayload] = useState<AssetProps[]>();

    const {
        response: claimedDevicesList,
        error: claimedDevicesError,
        loading: claimedDevicesLoading,
        status: claimedDevicesStatus,
        fetchData: fetchClaimedDevices,
    } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'GET',
            url: API_URL_PATH_DM_CLAIMED,
        },
        { manual: true }
    );

    useEffect(() => {
        if (action === 'Add') {
            fetchClaimedDevices();
        } else if (action === 'Remove') {
            const assets = selectedGroup[0].assets;
            const assetsInGroup = assets.map((asset) => ({
                label: asset.assetId,
                value: asset.assetId,
            }));

            setAllAssets(assetsInGroup);
        }
    }, []);

    useEffect(() => {
        if (claimedDevicesList) {
            const selectedGroupDeviceNameArray = (selectedGroup[0]?.assets || []).map(asset => asset?.assetId);

            const deviceNames = claimedDevicesList.items.map((device: Asset) => ({
                disabled: selectedGroupDeviceNameArray.includes(device.name),
                label: device.friendlyName,
                description: device.description,
                labelTag: device.name,
                value: device.name,
            }));

            setAllAssets(deviceNames);
        }
    }, [claimedDevicesList, claimedDevicesStatus]);

    const {
        fetchData: addRemoveAssets,
        error: addRemoveAssetsError,
        loading: addRemoveAssetsLoading,
        status: addRemoveAssetsStatus,
    } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'PATCH',
            url: `${API_URL_PATH_GROUP_LIST}/${selectedGroup[0]?.groupId}/${action === 'Add' ? 'addassets' : 'removeassets'
                }`,
            data: {
                assets: assetPayload,
            },
        },
        { manual: true }
    );

    useEffect(() => {
        if (assetPayload?.length) {
            addRemoveAssets();
        }
    }, [assetPayload]);

    useEffect(() => {
        if (addRemoveAssetsStatus === 200) {
            refetch();
            onDiscard();
        }
    }, [addRemoveAssetsStatus]);

    const handleSubmit = () => {
        const assetsObj = selectedAssets.map((asset) => ({
            assetCategory: 'device',
            assetId: asset.value || '',
        }));

        setAssetPayload(assetsObj);
    };

    return (
        <Modal
            onDismiss={onDiscard}
            visible={visible}
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={onDiscard}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleSubmit}
                            loading={addRemoveAssetsLoading}
                        >
                            Submit
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`${action} device(s)`}
        >
            <FormField label='Assets'>
                <Multiselect
                    selectedOptions={selectedAssets}
                    onChange={({ detail }) =>
                        setSelectedAssets(detail.selectedOptions)
                    }
                    deselectAriaLabel={(e) => `Remove ${e.label}`}
                    options={allAssets}
                    placeholder='Choose options'
                    selectedAriaLabel='Selected'
                    loadingText='Loading assets'
                    statusType={claimedDevicesLoading ? 'loading' : 'finished'}
                    errorText={claimedDevicesError}
                    empty='No options'
                />
                {addRemoveAssetsError && <p>{addRemoveAssetsError}</p>}
            </FormField>
        </Modal>
    );
};

export default AddRemoveAssetModal;
