import { Header, Grid, Container, SpaceBetween } from '@cloudscape-design/components';

import AggregateData from 'components/dashboard-aggregate/AggregateData';
import LocationHierarchy from 'components/location-hierarchy/index';
import { useDashboardContext } from 'providers/DashboardProvider';
import DeviceCardContainer from 'components/device-card-container';
import DeviceChart from 'components/device-chart';
import { useEffect, useState } from 'react';
import { LocationNode } from 'types/custom';

const DashboardPage = () => {
  const { selectedNode, selectedMeasurements, dateTimeRange } = useDashboardContext();
  const [zoomRange, setZoomRange] = useState<{
    min: number | undefined;
    max: number | undefined;
  }>({
    min: undefined,
    max: undefined,
  });

  useEffect(() => {
    setZoomRange({
      min: undefined,
      max: undefined,
    });
  }, [dateTimeRange]);

  return (
    <Grid
      gridDefinition={[
        { colspan: { m: 3, xs: 4, default: 12 } },
        { colspan: { m: 9, xs: 8, default: 12 } },
      ]}
    >
      <Container header={<Header variant="h2">
        Devices Hierarchy
      </Header>}>
        <LocationHierarchy />
      </Container>

      <SpaceBetween size="m">
        {(selectedNode as LocationNode)?.title?.toLowerCase() === 'building' ? (
          <AggregateData />
        ) : (
          <>
            <DeviceCardContainer />
            {Array.from(selectedMeasurements).map(
              ([measurement, deviceIds]) =>
                deviceIds.length > 0 && (
                  <DeviceChart
                    key={measurement}
                    measurement={measurement}
                    deviceIds={deviceIds}
                    zoomRange={zoomRange}
                    setZoomRange={setZoomRange}
                  />
                ),
            )}
          </>
        )}
      </SpaceBetween>
    </Grid>
  );
};

export default DashboardPage;
