import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { userManagerAPI } from 'api';
import DeviceSelect from 'components/device-select';
import {
    API_URL_PATH_GROUP_LIST,
    URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssetProps } from 'types/custom';

const CreateDeviceGroup = () => {
    const [groupId, setGroupId] = useState('');
    const [friendlyName, setFriendlyName] = useState('');
    const [description, setDescription] = useState('');

    const [selectedAssets, setSelectedAssets] = useState<
        readonly OptionDefinition[]
    >([]);
    const [assetPayload, setAssetPayload] = useState<AssetProps[]>();

    const [disableFormFields, setDisableFormFields] = useState(false);

    const navigate = useNavigate();

    const {
        status: createDeviceGroupStatus,
        error: createDeviceGroupError,
        loading: createDeviceGroupLoading,
        fetchData: createDeviceGroup,
    } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'POST',
            url: API_URL_PATH_GROUP_LIST,
            data: {
                groupId,
                description,
                friendlyName,
                assets: assetPayload || [],
            },
        },
        { manual: true }
    );

    useEffect(() => {
        if (createDeviceGroupStatus === 201) {
            navigate(URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST, {
                state: {
                    action: 'create-device-group',
                    status: 'success',
                    message: `Successfully created Device Group ${groupId}`,
                },
            });
        } else if (createDeviceGroupStatus > 201) {
            setDisableFormFields(false);
        }
    }, [createDeviceGroupStatus]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setDisableFormFields(true);
        const assetsObj = selectedAssets.map((asset) => ({
            assetCategory: 'device',
            assetId: asset.value || '',
        }));
        setAssetPayload(assetsObj);
        createDeviceGroup();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            formAction='none'
                            variant='link'
                            onClick={() =>
                                navigate('/device-group-manager/list', {})
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            loading={createDeviceGroupLoading}
                        >
                            Submit
                        </Button>
                    </SpaceBetween>
                }
                header={
                    <Header variant='h2' description=''>
                        Create a Device Group
                    </Header>
                }
                errorText={createDeviceGroupError}
            >
                <SpaceBetween direction='vertical' size='l'>
                    <FormField label='Group Name'>
                        <Input
                            disabled={disableFormFields}
                            value={groupId}
                            onChange={(event) => setGroupId(event.detail.value)}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Description <i>- optional</i>{' '}
                            </span>
                        }
                    >
                        <Input
                            disabled={disableFormFields}
                            value={description}
                            onChange={(event) =>
                                setDescription(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField label='Friendly Name'>
                        <Input
                            disabled={disableFormFields}
                            value={friendlyName}
                            onChange={(event) =>
                                setFriendlyName(event.detail.value)
                            }
                        />
                    </FormField>

                    <DeviceSelect
                        disabled={disableFormFields}
                        selectedAssets={selectedAssets}
                        setSelectedAssets={setSelectedAssets}
                    />
                </SpaceBetween>
            </Form>
        </form>
    );
};

export default CreateDeviceGroup;
