import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
} from '@cloudscape-design/components';

import Location from 'components/location';
import useFetch from 'hooks/useFetch';
import {
    API_URL_PATH_UM_ROLES,
    URL_FULL_PATH_USER_MANAGER_ROLES,
} from 'constants/urls';
import { userManagerAPI } from 'api';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import DeviceGroups from 'components/device-groups';

const CreateRole = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [privilege, setPrivilege] = useState<OptionDefinition | null>(null);
    const [idpgroup, setIdpgroup] = useState('');
    const [selectedDeviceGroups, setSelectedDeviceGroups] = useState<
        readonly OptionDefinition[]
    >([]);

    const [region, setRegion] = useState<OptionDefinition | null>(null);
    const [country, setCountry] = useState<OptionDefinition | null>(null);
    const [site, setSite] = useState<OptionDefinition | null>(null);

    const [disableFormFields, setDisableFormFields] = useState(false);
    const [roleValidationError, setRoleValidationError] = useState('');

    const navigate = useNavigate();

    const {
        status: createRoleStatus,
        error: createRoleError,
        loading: createRoleLoading,
        fetchData: createRole,
    } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'POST',
            url: API_URL_PATH_UM_ROLES,
            data: {
                roleId: name,
                description,
                privilege: privilege?.value,
                idpgroup,
                groupIds: selectedDeviceGroups.map(
                    (deviceGroup) => deviceGroup.value
                ),
                locations: [
                    `/location/region=${region?.value}/country=${country?.value}/site=${site?.value}`,
                ],
            },
        },
        { manual: true }
    );

    useEffect(() => {
        if (createRoleStatus === 201) {
            navigate(URL_FULL_PATH_USER_MANAGER_ROLES, {
                state: {
                    action: 'create-role',
                    status: 'success',
                    message: `Successfully created role ${name}`,
                },
            });
        } else if (createRoleStatus > 201) {
            setDisableFormFields(false);
        }
    }, [createRoleStatus]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const isValid =
            name &&
            privilege?.value &&
            idpgroup &&
            selectedDeviceGroups[0]?.value;

        if (isValid) {
            setRoleValidationError('');
            setDisableFormFields(true);
            createRole();
        } else {
            setRoleValidationError('Enter all required fields');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            formAction='none'
                            variant='link'
                            onClick={() => navigate('/user-manager/roles', {})}
                        >
                            Cancel
                        </Button>
                        <Button variant='primary' loading={createRoleLoading}>
                            Submit
                        </Button>
                    </SpaceBetween>
                }
                header={
                    <Header variant='h2' description=''>
                        Create a Role
                    </Header>
                }
                errorText={roleValidationError || createRoleError}
            >
                <SpaceBetween direction='vertical' size='l'>
                    <FormField label='Role Name'>
                        <Input
                            disabled={disableFormFields}
                            value={name}
                            onChange={(event) => setName(event.detail.value)}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Description <i>- optional</i>{' '}
                            </span>
                        }
                    >
                        <Input
                            disabled={disableFormFields}
                            value={description}
                            onChange={(event) =>
                                setDescription(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField label='Privilege'>
                        <Select
                            selectedOption={privilege}
                            onChange={({ detail }) =>
                                setPrivilege(detail.selectedOption)
                            }
                            placeholder='Choose an option'
                            options={[
                                { label: 'ems_admin', value: 'ems_admin' },
                                {
                                    label: 'ems_installer',
                                    value: 'ems_installer',
                                },
                                { label: 'ems_user', value: 'ems_user' },
                            ]}
                            disabled={disableFormFields}
                            empty='No options'
                        />
                    </FormField>

                    <FormField label='AD Group'>
                        <Input
                            disabled={disableFormFields}
                            value={idpgroup}
                            onChange={(event) =>
                                setIdpgroup(event.detail.value)
                            }
                        />
                    </FormField>

                    <DeviceGroups
                        selectedItem={selectedDeviceGroups}
                        setSelectedItem={setSelectedDeviceGroups}
                        selectionType='multi'
                    />

                    <Location
                        region={region}
                        country={country}
                        site={site}
                        setRegion={setRegion}
                        setCountry={setCountry}
                        setSite={setSite}
                        disableFormFields={disableFormFields}
                        deepLocations={false}
                    />
                </SpaceBetween>
            </Form>
        </form>
    );
};

export default CreateRole;
