import { DateRangePickerProps } from "@cloudscape-design/components";
import { relativeTimeMap, timeUnitMap } from "./const";

function getDateTimeRange(dateTimeRange: DateRangePickerProps.Value) {
  if (dateTimeRange.type === 'absolute') {
    return {
      from: Date.parse(dateTimeRange.startDate),
      to: Date.parse(dateTimeRange.endDate)
    }
  }

  let from = Date.now() - relativeTimeMap.get(dateTimeRange.key!)!;
  const to = Date.now();

  if (!dateTimeRange.key && dateTimeRange.unit) {
    from = Date.now() - dateTimeRange.amount * timeUnitMap.get(dateTimeRange.unit)!;
  }

  return {
    from,
    to
  }
}

export default getDateTimeRange;