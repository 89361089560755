import React, { useEffect, useState } from 'react';

import {
    API_URL_UM_RESOURCE_REGIONS,
    API_URL_UM_RESOURCE_COUNTRIES,
    API_URL_UM_RESOURCE_SITES,
    API_URL_UM_RESOURCE_BUILDINGS,
    API_URL_UM_RESOURCE_FLOORS,
    API_URL_UM_RESOURCE_ROOMS,
    API_URL_PATH_LOCATIONS_HIERARCHY,
} from 'constants/urls';
import {
    Alert,
    FormField,
    Select,
    SelectProps,
    SpaceBetween,
} from '@cloudscape-design/components';
import { locationAPI, deviceManagerAPI } from 'api';
import { selectLocationProps } from 'utils';
import useFetch from 'hooks/useFetch';

const Location = ({
    region,
    country,
    site,
    building,
    floor,
    room,
    disableFormFields,
    setRegion,
    setCountry,
    setSite,
    setBuilding,
    setFloor,
    setRoom,
    deepLocations,
}: {
    region: any;
    country: any;
    site: any;
    building?: any;
    floor?: any;
    room?: any;
    disableFormFields: boolean;
    setRegion: React.Dispatch<React.SetStateAction<any>>;
    setCountry: React.Dispatch<React.SetStateAction<any>>;
    setSite: React.Dispatch<React.SetStateAction<any>>;
    setBuilding?: any;
    setFloor?: any;
    setRoom?: any;
    deepLocations: boolean;
}) => {
    const [allRegions, setAllRegions] = useState<SelectProps.Options>([]);
    const [allCountries, setAllCountries] = useState<SelectProps.Options>([]);
    const [allSites, setAllSites] = useState<SelectProps.Options>([]);

    const [allBuildings, setAllBuildings] = useState<SelectProps.Options>([]);
    const [allFloors, setAllFloors] = useState<SelectProps.Options>([]);
    const [allRooms, setAllRooms] = useState<SelectProps.Options>([]);

    const addRecentlyUsed = (data: any, stored: any, selectUpTo: number[] = [], isLoading: boolean = false) => {
        let parsed = stored || [];
        selectUpTo = selectUpTo.filter(x => x !== undefined)
        while (selectUpTo.length > 0) {
            const selectedId = selectUpTo.shift()?.toString()
            parsed = parsed.find((loc: { [id: string]: string; }) => loc['id'] === selectedId)?.children || [];
        }
        parsed = parsed.map((loc: { [prop: string]: string; }) => ({
            label: loc['name'],
            value: Number(loc['id']),
            labelTag: loc['id'],
        }));
        return [{
            label: 'Recently Used',
            options: parsed
        }, {
            label: 'All Locations',
            options: !isLoading ? data : []
        }
        ];
    }

    // fetch stored locations
    const {
        response: storedData,
        error: storedError,
    } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'GET',
            url: API_URL_PATH_LOCATIONS_HIERARCHY,
        },
        { manual: false }
    );

    //fetch regions
    const {
        response: regionsData,
        error: regionsError,
        loading: regionsLoading,
    } = useFetch(
        {
            axiosInstance: locationAPI,
            method: 'GET',
            url: API_URL_UM_RESOURCE_REGIONS,
        },
        { manual: false }
    );

    //fetch countries
    const {
        response: countriesData,
        error: countriesError,
        loading: countriesLoading,
        fetchData: getAllCountries,
    } = useFetch(
        {
            axiosInstance: locationAPI,
            method: 'GET',
            url: `${API_URL_UM_RESOURCE_COUNTRIES}/${region?.value}?withchild=true`,
        },
        { manual: true }
    );

    //fetch sites
    const {
        response: sitesData,
        error: sitesError,
        loading: sitesLoading,
        fetchData: getAllSites,
    } = useFetch(
        {
            axiosInstance: locationAPI,
            method: 'GET',
            url: `${API_URL_UM_RESOURCE_SITES}/${country?.value}?withchild=true`,
        },
        { manual: true }
    );

    //fetch building
    const {
        response: buildingData,
        error: buildingError,
        loading: buildingLoading,
        fetchData: getAllBuilding,
    } = useFetch(
        {
            axiosInstance: locationAPI,
            method: 'GET',
            url: `${API_URL_UM_RESOURCE_BUILDINGS}/${site?.value}?withchild=true`,
        },
        { manual: true }
    );

    //fetch floors
    const {
        response: floorsData,
        error: floorsError,
        loading: floorsLoading,
        fetchData: getAllFloors,
    } = useFetch(
        {
            axiosInstance: locationAPI,
            method: 'GET',
            url: `${API_URL_UM_RESOURCE_FLOORS}/${building?.value}?withchild=true`,
        },
        { manual: true }
    );

    //fetch rooms
    const {
        response: roomsData,
        error: roomsError,
        loading: roomsLoading,
        fetchData: getAllRooms,
    } = useFetch(
        {
            axiosInstance: locationAPI,
            method: 'GET',
            url: `${API_URL_UM_RESOURCE_ROOMS}/${floor?.value}?withchild=true`,
        },
        { manual: true }
    );

    useEffect(() => {
        if (regionsData) {
            const regionsArray: any[] = regionsData['child'];
            const regionsList = regionsArray?.map((data) =>
                selectLocationProps(data, 'region')
            );
            setAllRegions(regionsList || []);
        }
    }, [regionsData]);

    useEffect(() => {
        if (countriesData) {
            const countriesArray: any[] = countriesData['child'];
            const countriesList = countriesArray?.map((data) =>
                selectLocationProps(data, 'country')
            );
            setAllCountries(countriesList || []);
        }
    }, [countriesData]);

    useEffect(() => {
        if (sitesData) {
            const sitesArray: any[] = sitesData['child'];
            const sitesList = sitesArray?.map((data) =>
                selectLocationProps(data, 'site')
            );
            setAllSites(sitesList || []);
        }
    }, [sitesData]);

    useEffect(() => {
        if (buildingData) {
            const buildingArray: any[] = buildingData['child'];
            const buildingList = buildingArray?.map((data) =>
                selectLocationProps(data, 'building')
            );
            setAllBuildings(buildingList || []);
        }
    }, [buildingData]);

    useEffect(() => {
        if (floorsData) {
            const floorsArray: any[] = floorsData['child'];
            const floorsList = floorsArray?.map((data) =>
                selectLocationProps(data, 'floor')
            );
            setAllFloors(floorsList || []);
        }
    }, [floorsData]);

    useEffect(() => {
        if (roomsData) {
            const roomsArray: any[] = roomsData['child'];
            const roomsList = roomsArray?.map((data) =>
                selectLocationProps(data, 'room')
            );
            setAllRooms(roomsList || []);
        }
    }, [roomsData]);

    useEffect(() => {
        if (region && region.value) {
            setCountry('');
            setSite('');

            if (deepLocations) {
                setBuilding('');
                setFloor('');
                setRoom('');
            }

            getAllCountries();
        }
    }, [region]);

    useEffect(() => {
        if (country && country.value) {
            setSite('');

            if (deepLocations) {
                setBuilding('');
                setFloor('');
                setRoom('');
            }

            getAllSites();
        }
    }, [country]);

    useEffect(() => {
        if (site && site.value) {
            if (deepLocations) {
                setBuilding('');
                setFloor('');
                setRoom('');
            }

            getAllBuilding();
        }
    }, [site]);

    useEffect(() => {
        if (building && building.value) {
            if (deepLocations) {
                setFloor('');
                setRoom('');
            }

            getAllFloors();
        }
    }, [building]);

    useEffect(() => {
        if (floor && floor.value) {
            if (deepLocations) {
                setRoom('');
            }

            getAllRooms();
        }
    }, [floor]);

    return (
        <>
            <SpaceBetween direction='vertical' size='l'>
                <FormField label='Region' errorText={regionsError}>
                    <Select
                        selectedOption={region}
                        onChange={({ detail }) =>
                            setRegion(detail.selectedOption)
                        }
                        placeholder='Choose an option'
                        loadingText='Loading regions'
                        options={addRecentlyUsed(allRegions, storedData)}
                        statusType={regionsLoading ? 'loading' : 'finished'}
                        disabled={disableFormFields}
                        empty='No regions found'
                        filteringType='manual'
                    />
                </FormField>

                <FormField label='Country' errorText={countriesError}>
                    <Select
                        selectedOption={country}
                        onChange={({ detail }) =>
                            setCountry(detail.selectedOption)
                        }
                        placeholder='Choose an option'
                        loadingText='Loading countries'
                        options={addRecentlyUsed(allCountries, storedData, [region?.value], countriesLoading)}
                        statusType={countriesLoading ? 'loading' : 'finished'}
                        disabled={!region || disableFormFields}
                        empty='No countries found'
                        filteringType='manual'
                    />
                </FormField>

                <FormField label='Site' errorText={sitesError}>
                    <Select
                        selectedOption={site}
                        onChange={({ detail }) =>
                            setSite(detail.selectedOption)
                        }
                        placeholder='Choose an option'
                        loadingText='Loading sites'
                        options={addRecentlyUsed(allSites, storedData, [region?.value, country?.value], sitesLoading)}
                        statusType={sitesLoading ? 'loading' : 'finished'}
                        disabled={!country || disableFormFields}
                        empty='No sites found'
                        filteringType='manual'
                    />
                </FormField>
                {deepLocations && (
                    <>
                        <FormField label='Building' errorText={buildingError}>
                            <Select
                                selectedOption={building}
                                onChange={({ detail }) =>
                                    setBuilding(detail.selectedOption)
                                }
                                placeholder='Choose an option'
                                loadingText='Loading buildings'
                                options={addRecentlyUsed(allBuildings, storedData, [region?.value, country?.value, site?.value], buildingLoading)}
                                statusType={buildingLoading ? 'loading' : 'finished'}
                                disabled={!site || disableFormFields}
                                empty='No buildings found'
                                filteringType='manual'
                            />
                        </FormField>

                        <FormField label='Floor' errorText={floorsError}>
                            <Select
                                selectedOption={floor}
                                onChange={({ detail }) =>
                                    setFloor(detail.selectedOption)
                                }
                                placeholder='Choose an option'
                                loadingText='Loading floors'
                                options={addRecentlyUsed(allFloors, storedData, [region?.value, country?.value, site?.value, building?.value], floorsLoading)}
                                statusType={floorsLoading ? 'loading' : 'finished'}
                                disabled={!building || disableFormFields}
                                empty='No floors found'
                                filteringType='manual'
                            />
                        </FormField>

                        <FormField label='Room' errorText={roomsError}>
                            <Select
                                selectedOption={room}
                                onChange={({ detail }) =>
                                    setRoom(detail.selectedOption)
                                }
                                placeholder='Choose an option'
                                loadingText='Loading rooms'
                                options={addRecentlyUsed(allRooms, storedData, [region?.value, country?.value, site?.value, building?.value, floor?.value], roomsLoading)}
                                statusType={roomsLoading ? 'loading' : 'finished'}
                                disabled={!floor || disableFormFields}
                                empty='No room found'
                                filteringType='manual'
                            />
                        </FormField>
                        {storedError && <Alert type="warning" header='Error loading recently used locations'>{storedError}</Alert>}
                    </>
                )}
            </SpaceBetween>
        </>
    );
};

export default Location;
