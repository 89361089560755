import { createContext, useContext, useEffect, useState } from 'react';
import { Box, ColumnLayout } from '@cloudscape-design/components';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { usePageLayoutContext } from 'components/common/layout';
import DeviceTable from 'components/device-manager/DeviceTable';
import DeviceTabs from 'components/device-manager/DeviceTabs';
import Spinner from 'components/spinner/Spinner';
import useFetch from 'hooks/useFetch';
import { deviceManagerAPI } from 'api';
import {
    API_URL_PATH_DM_CLAIMED,
    API_URL_PATH_DM_DEVICE,
    API_URL_PATH_DM_UNCLAIMED,
} from 'constants/urls';
import { Asset } from 'types/custom';

export const DeviceManagerContext = createContext({
    allDevices: [] as Asset[],
    deviceLoading: false,
    deviceError: '',
    setSelectedDevices: (asset: Asset[]) => { },
    selectedDevices: [] as Asset[],
    getAllDevices: () => { },
    activeTabId: '',
    setActiveTabId: (state: string) => { },
    getDeviceDetails: () => { },
    refetchDeviceDetails: () => { },
    deviceResponse: {} as any,
    deviceStatus: 0,
});

export const useDeviceManagerContext = () => useContext(DeviceManagerContext);

const DeviceListPage = () => {
    const [selectedDevices, setSelectedDevices] = useState<Asset[]>([]);
    const [allItems, setAllItems] = useState<Asset[]>([]);
    const [activeTabId, setActiveTabId] = useState<string>('details');
    const [deviceLoading, setDeviceLoading] = useState(true);
    const [deviceError, setDeviceError] = useState('');

    const { setNotification } = usePageLayoutContext();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location?.state?.action) {
            setNotification([
                {
                    type: 'success',
                    content: location.state.message,
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setNotification([]),
                    id: `flash-${location.state.action}`,
                },
            ]);

            navigate(location.pathname, {});
        }
    }, [location?.state?.action]);

    const {
        response: claimedResponse,
        error: claimedError,
        loading: claimedLoading,
        fetchData: getClaimedDevices,
    } = useFetch<Asset[]>(
        {
            axiosInstance: deviceManagerAPI,
            method: 'GET',
            url: API_URL_PATH_DM_CLAIMED,
        },
        { manual: true }
    );

    const {
        response: unClaimedResponse,
        error: unClaimedError,
        loading: unClaimedLoading,
        fetchData: getUnclaimedDevices,
    } = useFetch<Asset[]>(
        {
            axiosInstance: deviceManagerAPI,
            method: 'GET',
            url: API_URL_PATH_DM_UNCLAIMED,
        },
        { manual: true }
    );

    //get device details
    const {
        fetchData: getDeviceDetails,
        response: deviceResponse,
        status: deviceStatus,
        refetch: refetchDeviceDetails,
    } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'GET',
            url: `${API_URL_PATH_DM_DEVICE}/${selectedDevices[0]?.name}`,
        },
        { manual: true }
    );

    const getAllDevices = () => {
        axios.all([getClaimedDevices(), getUnclaimedDevices()]).then(
            axios.spread((...response) => {
                const claimedDeviceList: Asset[] = response[0]?.data?.items || [];
                const unClaimedDeviceList: Asset[] = response[1]?.data?.results || [];
                if (claimedDeviceList && unClaimedDeviceList) {
                    setAllItems(claimedDeviceList.concat(unClaimedDeviceList));
                }
            })
        );
    };

    //combine the response
    useEffect(() => {
        getAllDevices();
    }, []);

    useEffect(() => {
        setDeviceLoading(claimedLoading || unClaimedLoading);
    }, [claimedLoading, unClaimedLoading]);

    useEffect(() => {
        if (claimedError && unClaimedError) {
            setDeviceError(claimedError || unClaimedError);
        } else {
            setDeviceError('');
        }
    }, [claimedError, unClaimedError]);

    return (
        <DeviceManagerContext.Provider
            value={{
                deviceLoading,
                allDevices: allItems,
                deviceError,
                setSelectedDevices,
                selectedDevices,
                getAllDevices,
                activeTabId,
                setActiveTabId,
                getDeviceDetails,
                refetchDeviceDetails,
                deviceResponse,
                deviceStatus,
            }}
        >
            <ColumnLayout variant='text-grid'>
                {deviceLoading && unClaimedLoading && (
                    <>
                        <br></br>
                        <Spinner />
                    </>
                )}
                {!deviceLoading && (claimedResponse || unClaimedResponse) && (
                    <DeviceTable showActions  selectionType='multi' />
                )}
            </ColumnLayout>

            {(claimedResponse || unClaimedResponse) && (
                <>
                    <Box margin={{ top: 'xxxl' }}></Box>
                    <ColumnLayout variant='text-grid'>
                        <DeviceTabs selectedDevices={selectedDevices} />
                    </ColumnLayout>
                </>
            )}
        </DeviceManagerContext.Provider>
    );
};

export default DeviceListPage;
