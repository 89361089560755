import {
    Grid,
    ColumnLayout,
    Container,
    Header,
    Button,
} from '@cloudscape-design/components';
import GroupsList from './GroupsList';
import GroupsView from './GroupsView';
import { useIncidentManagerContext } from 'pages/incident-manager/IncidentManagerPage';

const NotificationGroups = () => {
    const { groupsRefetch, groupsFetching } = useIncidentManagerContext(); 
    return (
        <Grid
            gridDefinition={[
                { colspan: { m: 4, s: 3, xs: 12, xxs: 12 } },
                { colspan: { m: 8, s: 9, xs: 12, xxs: 12 } },
            ]}
        >
            <ColumnLayout variant='text-grid'>
                <Container
                    header={<Header variant='h3' actions={
                        <Button iconName='refresh' onClick={groupsRefetch} loading={groupsFetching}/>
                    }>Notification Groups</Header>}
                    fitHeight={true}
                >
                    <GroupsList />
                </Container>
            </ColumnLayout>
            <ColumnLayout variant='text-grid'>
                <Container fitHeight={true}>
                    <GroupsView />
                </Container>
            </ColumnLayout>
        </Grid>
    );
};

export default NotificationGroups;
