import { useNavigate } from 'react-router';
import { useEffect, useState, useRef, useCallback } from 'react';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (401 === error.response.status) {
            console.log('interceptor error', error);
        } else {
            return Promise.reject(error);
        }
    }
);

const useFetch = <T = any>(config: {
    axiosInstance: AxiosInstance;
    method: string;
    url?: string;
    data?: any;
}, { manual }: { manual: boolean }) => {
    const [response, setResponse] = useState<T>();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<number>(0);
    const [reload, setReload] = useState(0);
    let manualReloads = useRef(manual);
    let controller = useRef(new AbortController());

    const { axiosInstance, method, url, data } = config;
    const navigate = useNavigate();

    const fetchData = useCallback(async (url?: string, bodyData?: any) => {
        setLoading(true);
        if (controller.current.signal.aborted) controller.current = new AbortController();
        setStatus(0);
        try {

            const isFormData = data instanceof FormData;

            if (method.toLowerCase() === 'post') {
                axios.defaults.data = data;
            }

            const result = await axiosInstance.request({
                method: method.toLowerCase(),
                url: url || config.url,
                data: ['GET', 'DELETE'].includes(method)
                    ? undefined
                    : isFormData
                        ? config.data
                        : bodyData
                            ? JSON.stringify(bodyData)
                            : JSON.stringify(config.data),
                signal: controller.current?.signal,
                headers: {
                    'Content-Type': isFormData
                        ? 'multipart/form-data'
                        : 'application/json',
                    Authorization: `Bearer ${(await Auth.currentSession())
                        .getIdToken()
                        .getJwtToken()}`,
                },
            });
            setResponse(result?.data as T);
            setStatus(result?.status);
            setError('');
            return result;
        } catch (error: any) {
            if (error.response) {
                setError((Array.isArray(error.response.data.message) ? error.response.data.message.join(". ") : error.response.data.message) || 'Bad request');
                setStatus(error?.response?.status);
            } else if (error.message) {
                setError(error.message);
            } else if (error.request) {
                setError(error.request.toString());
                setStatus(502);
            } else {
                setError(error.toString());
                setStatus(502);
            }

            if (error?.response?.status === 403) {
                navigate('/unauthorized');
            }
            if (error?.message.toString() === 'canceled')
                return 'canceled' as any;
            return error?.response as AxiosResponse;
        } finally {
            setLoading(false);
        }
    }, [data, method, axiosInstance, url, navigate, controller]);

    const refetch = useCallback((newManual: boolean | null = null) => {
        setReload((prev: number) => prev + 1);
        if (newManual !== null) manualReloads.current = newManual
        fetchData();
    }, [fetchData]);


    useEffect(() => {
        if (!manualReloads.current) fetchData();

        return () => controller.current?.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, controller]);

    return { response, error, loading, status, fetchData, refetch };
};

export default useFetch;
