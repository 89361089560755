import { FormField, Multiselect } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { deviceManagerAPI } from 'api';
import { API_URL_PATH_DM_CLAIMED } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';

const DeviceSelect = ({
    selectedAssets,
    setSelectedAssets,
    disabled,
}: {
    selectedAssets: readonly OptionDefinition[];
    setSelectedAssets: (state: readonly OptionDefinition[]) => void;
    disabled: boolean;
}) => {
    const [allAssets, setAllAssets] = useState<OptionDefinition[]>();

    const {
        response: claimedDevicesList,
        error: claimedDevicesError,
        loading: claimedDevicesLoading,
        status: claimedDevicesStatus,
    } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'GET',
            url: API_URL_PATH_DM_CLAIMED,
        },
        { manual: false }
    );

    useEffect(() => {
        if (claimedDevicesList) {
            const claimedDevices = claimedDevicesList.items;

            const deviceOptions = claimedDevices?.map((device: any) => ({
                label: device.name,
                value: device.name,
            }));

            setAllAssets(deviceOptions);
        }
    }, [claimedDevicesList, claimedDevicesStatus]);

    return (
        <FormField
            label={
                <span>
                    Devices <i>- optional</i>{' '}
                </span>
            }
        >
            <Multiselect
                selectedOptions={selectedAssets}
                onChange={({ detail }) =>
                    setSelectedAssets(detail.selectedOptions)
                }
                disabled={disabled}
                filteringType='auto'
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={allAssets}
                placeholder='Choose options'
                selectedAriaLabel='Selected'
                loadingText='Loading assets'
                statusType={claimedDevicesLoading ? 'loading' : 'finished'}
                errorText={claimedDevicesError}
                empty='No options'
            />
        </FormField>
    );
};

export default DeviceSelect;
