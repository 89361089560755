import { SegmentedControl } from '@cloudscape-design/components';

export type ThresholdPickerProps = {
  selectedId?: string;
  onChange: (selectedId: string) => void;
  disabled?: boolean;
}

export const ThresholdPicker = ({ 
  selectedId,
  onChange,
  disabled,
}: ThresholdPickerProps) => {
 
  return (
    <SegmentedControl
      selectedId={selectedId || null}
      onChange={({ detail }) => {
        onChange?.(detail.selectedId);
      }}
      options={[
        {
          text: 'High-High',
          id: 'HH',
          disabled,
        },
        {
          text: 'High',
          id: 'H',
          disabled,
        },
        {
          text: 'Low-Low',
          id: 'LL',
          disabled,
        },
        {
          text: 'Low',
          id: 'L',
          disabled,
        },
      ]}
    />
  );
};
