import DateRangePicker, { DateRangePickerProps } from '@cloudscape-design/components/date-range-picker';

import { useDashboardContext } from 'providers/DashboardProvider';

const DateTimePicker = () => {
    const { dateTimeRange, setDateTimeRange } = useDashboardContext();

    return (
        <DateRangePicker
            onChange={({ detail }) => setDateTimeRange(detail.value ?? dateTimeRange)}
            rangeSelectorMode='default'
            absoluteFormat="long-localized"
            hideTimeOffset
            value={dateTimeRange}
            showClearButton={false}
            relativeOptions={[
                {
                    key: "previous-5-minutes",
                    amount: 5,
                    unit: "minute",
                    type: "relative"
                },
                {
                    key: "previous-30-minutes",
                    amount: 30,
                    unit: "minute",
                    type: "relative"
                },
                {
                    key: "previous-1-hour",
                    amount: 1,
                    unit: "hour",
                    type: "relative"
                },
                {
                    key: "previous-6-hours",
                    amount: 6,
                    unit: "hour",
                    type: "relative"
                }
            ]}
            isValidRange={(range: DateRangePickerProps.Value | null) => {
                if (range?.type === 'absolute') {
                    const [startDateWithoutTime] = range.startDate.split('T');
                    const [endDateWithoutTime] = range.endDate.split('T');
                    if (!startDateWithoutTime || !endDateWithoutTime) {
                        return {
                            valid: false,
                            errorMessage:
                                'The selected date range is incomplete. Select a start and end date for the date range.',
                        };
                    }
                }
                return { valid: true };
            }}
            i18nStrings={{
                todayAriaLabel: 'Today',
                nextMonthAriaLabel: 'Next month',
                previousMonthAriaLabel: 'Previous month',
                customRelativeRangeDurationLabel: 'Duration',
                customRelativeRangeDurationPlaceholder: 'Enter duration',
                customRelativeRangeOptionLabel: 'Custom range',
                customRelativeRangeOptionDescription: 'Set a custom range in the past',
                customRelativeRangeUnitLabel: 'Unit of time',
                formatRelativeRange: (e) => {
                    const n = 1 === e.amount ? e.unit : `${e.unit}s`;
                    return `Last ${e.amount} ${n}`;
                },
                formatUnit: (e, n) => (1 === n ? e : `${e}s`),
                relativeModeTitle: 'Relative range',
                absoluteModeTitle: 'Absolute range',
                relativeRangeSelectionHeading: 'Choose a range',
                startDateLabel: 'Start date',
                endDateLabel: 'End date',
                startTimeLabel: 'Start time',
                endTimeLabel: 'End time',
                clearButtonLabel: 'Clear and dismiss',
                cancelButtonLabel: 'Cancel',
                applyButtonLabel: 'Apply',
            }}
            placeholder='Filter by date and time range'
        />
    );
};

export default DateTimePicker;
