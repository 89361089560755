import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthContext = createContext({
    user: '',
    roles: [],
    privileges: '',
    token: '',
    logout: () => {},
    error: '',
    loading: true,
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState('');
    const [token, setToken] = useState('');
    const [roles, setRoles] = useState([]);
    const [privileges, setPrivileges] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user?.attributes?.email);
                setToken(user?.signInUserSession?.idToken?.jwtToken);

                const decodedJWT: any =
                    user &&
                    jwt_decode(user?.signInUserSession?.idToken?.jwtToken);

                const parsePermissions = JSON.parse(decodedJWT?.permissions);

                setRoles(parsePermissions[0]?.permissions);
                setPrivileges(parsePermissions[0]?.privilege);
            })
            .catch((error) => {
                console.log(error);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            if (event === 'customOAuthState') {
                const routeData = decodeURIComponent(data);
                if (routeData) {
                    navigate(routeData);
                } else {
                    navigate('/');
                }
            }
        });
    }, []);

    const logout = () => {
        try {
            Auth.signOut().then(() => {
                console.log('signout');
            });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };

    const contextValue = useMemo(
        () => ({
            user,
            roles,
            privileges,
            token,
            logout,
            error,
            loading,
        }),
        [user, roles, privileges, token, error, loading]
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
