import axios, { AxiosInstance } from 'axios';

export const locationAPI: AxiosInstance = axios.create({
    baseURL: window['runtimeConfig'].locationProxyApiUrl,
});

export const userManagerAPI: AxiosInstance = axios.create({
    baseURL: window['runtimeConfig'].userManagerApiUrl,
});

export const deviceManagerAPI: AxiosInstance = axios.create({
    baseURL: window['runtimeConfig'].deviceManagerApiUrl,
});

export const incidentManagerAPI: AxiosInstance = axios.create({
    baseURL: window['runtimeConfig'].incidentManagerApiUrl,
});
