import { Container } from '@cloudscape-design/components';
import InstallDevice from 'components/device-manager/InstallDevice';

const InstallDevicePage = () => {
    return (
        <Container>
            <InstallDevice />
        </Container>
    );
};

export default InstallDevicePage;
